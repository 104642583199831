import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router";
import { loadRma } from "../../../actions/ot/ot-detail";
import { isCS, isWatchmaker, isPDV } from "../../../helpers/permission";
import { Comments } from "../../Comments";
import { OTCommentBox } from "./OTCommentBox";
import { OTDetailInfo } from "./OTDetailInfo";

export const OTDetail = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const ot = useSelector((state) => state.ot);
  const { name: role } = useSelector((state) => state.auth.role);

  let { slug } = useParams();
  useEffect(() => {
    dispatch(loadRma(slug)).catch((route) => push(route));
  }, [slug, dispatch, push]);
  return (
    <div className="panel_cliente_marco_interno">
      <div className="seccion-detalle-2">
        <h5>Comentarios</h5>
        <div className="seccion-detalle-comentarios">
          <Comments comments={ot.comments} />
        </div>
        {!isPDV(role) && (
          <div className="comment-box">
            <OTCommentBox slug={slug} status={ot.detail.status} rma={ot.rma} />
          </div>
        )}
      </div>

      {ot && <OTDetailInfo ot={ot} />}
    </div>
  );
};
