import axios from "axios";
import { mainUrl as baseURL } from "vendor/env";
import { configNamesValidator } from "../helpers/config-names-validator";

/**
 * Función que permite inicializar correctamente una instancia de axios
 * con el baseUrl de la Api principal
 * @param {config} config Objeto que contiene la configuración para inicializar una Instancia de Axios
 * @returns AxiosInstance
 */
export const apiInstance = (config = {}) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  const basicConfig = {
    baseURL,
    headers,
  };
  const validatedConfig = configNamesValidator(config);
  /* @TODO: Hacer que acepte interceptores para peticiones y respuestas.

    Cada interceptor debería hacerse por separado, y debe devolver
    la petición o respuesta en cada función que se use como interceptor.

    Esto para que sea pasado por un *pipe* o un array.reduce.
  */
  const axiosInstance = axios.create({
    ...basicConfig,
    ...validatedConfig,
  });

  // axiosInstance.interceptors.request.use()

  return axiosInstance;
};
