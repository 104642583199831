import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { isWatchmaker, isCS } from '../../../helpers/permission'
import { loadRma, updateRma } from '../../../actions/ot/ot-detail'
import { useLocation } from 'react-router-dom'
import { otGetInfo } from '../../../actions/ot/ot-get'

export const OTCheckBudget = (props) => {
  const {name:role} = useSelector(state => state.auth.role)
  const [error, setError] = useState({status:false, message:''})
  const [confirmation, setConfirmation] = useState({status:'', comment:''})
  const dispatch = useDispatch()
  const {pathname} = useLocation()

  const {status, comment} = confirmation

  const handleConfirmation = async(e) => {
    if(status === '') {
      return setError({
        status:true, message:'Seleccione si confirma o rechaza el presupuesto'
      })
    }
    let fd = new FormData()
    fd.append('action','confirm')
    fd.append('option',status ? 'yes' : 'no')
    if(comment) {
      fd.append('comment',comment)
    }
    setError({status:false, message:''})
    await dispatch(updateRma(props.slug,fd))
    
    if(pathname.includes('panel/ot/confirmed')) {
      await dispatch(otGetInfo('budget-ready'));
      setConfirmation({status:'', comment:''})
      return props.onHide()
    } 

    if(isCS(role) || isWatchmaker(role)) {
      await dispatch(loadRma(props.slug))
      setConfirmation({status:'', comment:''})
      return props.onHide()
    }
  }
 /*  useEffect(() => {
    if(isWatchmaker(role)) {
      dispatch(otGetWatchmakersListToDerive(props.slug))
    } else if ( isCS(role)) {
      dispatch(otGetToAssignInfo('ot-to-technical'))
    }
  }, [dispatch,props.slug,role]) */
  return (
    
    <Modal
      show={props.show && props.show.status}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='ot_watchmaker_repair_modal'
    >
      <Modal.Header closeButton className='ot_watchmaker_repair_modal_top'>
        <Modal.Title id="contained-modal-title-vcenter watchmaker_to_derive_title">
          <h6>Confirmar presupuesto</h6>
          <span>OT #<strong>{props.slug}</strong></span>
          
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='ot_watchmaker_repair_modal_body m-0'>
        <div className='ot_confirm_rma_budget'>
            {
              error.status 
              ? <small className='text-danger'>{error.message}</small>
              : <small>¿Desea confirmar el presupuesto y actualizar el estado de la OT?</small>
            }
            <div className='radio_button_container'>
              <label htmlFor="confirm_budget">
                <input 
                  id='confirm_budget'
                  type='radio'
                  name='confirm_budget'
                  value='yes'
                  onClick={() => setConfirmation({...confirmation,status:true})}
                />
                <span>Confirmar</span>
              </label>
              <label htmlFor="reject_budget">
                <input 
                  id='reject_budget'
                  type='radio'
                  name='confirm_budget'
                  value='no'
                  onClick={() => setConfirmation({...confirmation,status:false})}
                />
                <span>Rechazar</span>
              </label>
            </div>
            <div className='ot_confirm_rma_budget_comment'>
              <textarea
                value={confirmation.comment}
                onChange={ e => setConfirmation({...confirmation, comment:e.target.value})}
                placeholder='Comentarios adicionales'
              ></textarea>
            </div>
        </div>
      </Modal.Body>
      <Modal.Footer className='ot_watchmaker_repair_modal_bottom ot_watchmaker_derive_buttons'>
        <Button
          variant="success" 
          onClick={handleConfirmation}
        >
          <i className="fas fa-clipboard-check"></i>Actualizar
        </Button>

        <Button 
          variant="danger" 
          onClick={() => {
            setError({status:false, message:''})
            setConfirmation({status:'', comment:''})
            return props.onHide()
          }}
        >
          Volver
        </Button>

      </Modal.Footer>
    </Modal>
  
  )
}
