import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { otGetObservationsListWatchmaker } from "../../../actions/ot/ot-get";
import { otSendWatchmakerUpdate } from "../../../actions/ot/ot-update";
import { isWatchmaker } from "../../../helpers/permission";
import { OTWatchmakerRepairListType } from "./OTWatchmakerRepairListType";
import { OTWatchmakerToRepairList } from "./OTWatchmakerToRepairList";

export const OTWatchmakerSetReady = (props) => {
  const { name: role } = useSelector((state) => state.auth.role);
  const { ticket } = useSelector((state) => state.ot.watchmaker_info);
  const dispatch = useDispatch();
  const initialState = {
    repairType: null,
    observations: [],
    additionalComment: "",
    budget_amount: "",
    warranty: "",
  };
  const [toRepair, setToRepair] = useState(initialState);
  const [error, setError] = useState({ status: false, message: "" });
  const [toDeleteForm, setToDeleteForm] = useState(true);

  const handleWarranty = (e) => {
    setToRepair({
      ...toRepair,
      warranty: e.target.value,
    });
  };

  const handleDelete = async () => {
    setToRepair(initialState);

    await setToDeleteForm(false);
    await setToDeleteForm(true);
  };

  const {
    repairType,
    observations,
    additionalComment,
    warranty,
    budget_amount,
  } = toRepair;

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError({ status: false, message: "" });
    const fd = new FormData();

    if (repairType === null) {
      fd.append("repairType", "rep");
    } else {
      fd.append("repairType", repairType);
    }

    if (
      (repairType === null || repairType === "rep") &&
      observations.length < 1 &&
      !additionalComment
    ) {
      return setError({
        status: true,
        message: "Es necesario un resumen de las reparaciones",
      });
    }

    if (repairType === "prep" && budget_amount) {
      fd.append("budget_amount", budget_amount);
    }

    if (additionalComment) {
      fd.append("additionalComment", additionalComment);
    }

    if (observations.length > 0) {
      fd.append("observations", observations);
    }
    if (!!warranty) {
      fd.append("warranty", warranty);
    }
    await dispatch(otSendWatchmakerUpdate(fd, props.slug, "finish"));
    // for (const pair of fd.entries()) {
    //   console.log(`${pair[0]}, ${pair[1]}`);
    // }
  };

  useEffect(() => {
    if (isWatchmaker(role)) {
      dispatch(otGetObservationsListWatchmaker(props.slug));
    }
  }, [dispatch, props.slug, role]);
  return (
    <Modal
      show={props.show && props.show.status}
      onHide={props.onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="ot_watchmaker_repair_modal"
    >
      <Modal.Header closeButton className="ot_watchmaker_repair_modal_top">
        <Modal.Title id="contained-modal-title-vcenter ">
          <h6>Finalizar revisión de OT #{props.slug}</h6>
          <span>Seleccione el tipo de reparación y detalles del OT:</span>
          {error.status ? (
            <small className="text-danger">{error.message}</small>
          ) : (
            <small>
              Si no selecciona un tipo de reparación, el OT será tomado como
              "Reparación"
            </small>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="ot_watchmaker_repair_modal_body m-0">
        {toDeleteForm && (
          <>
            <OTWatchmakerRepairListType
              setToRepair={setToRepair}
              toRepair={toRepair}
            />
            <OTWatchmakerToRepairList
              setToRepair={setToRepair}
              toRepair={toRepair}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="ot_watchmaker_repair_modal_bottom">
        {toDeleteForm &&
          ticket.rma &&
          (ticket.rma.warranty ||
            ticket.rma.stock ||
            (ticket.rma.warrantyState && ticket.rma.warrantyState.active)) &&
          !ticket.rma.budget && (
            <div className="ot_watchmaker_warranty_state">
              <div>
                <input
                  type="radio"
                  name="warranty"
                  id="keep_warranty_state"
                  value="yes"
                  onChange={handleWarranty}
                />
                <label
                  className="form-check-label"
                  htmlFor="keep_warranty_state"
                >
                  Mantener estado
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  name="warranty"
                  id="reject_warranty_state"
                  value="noWarranty"
                  onChange={handleWarranty}
                />
                <label
                  className="form-check-label"
                  htmlFor="reject_warranty_state"
                >
                  Rechazar garantía
                </label>
              </div>
            </div>
          )}

        <Button variant="success" onClick={handleSubmit}>
          Enviar
        </Button>

        <Button variant="warning" onClick={handleDelete}>
          Borrar
        </Button>

        <Button variant="danger" onClick={props.onHide}>
          Volver
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
