import React, { useContext } from 'react'
import { useDrag } from 'react-dnd'
import { dndTypes } from '../../../../types/dnd'
import { OTDistToSendContext } from './OTMassiveDistribution'

export const OTDragCard = ({ticket}) => {

  const {markAsPassed} = useContext(OTDistToSendContext)

  const slug = (ticket.rma && ticket.rma.slug) ? ticket.rma.slug : ticket.slug
  const [ { isDragging }, drag ] = useDrag({
    type: dndTypes.CARD,
    item: {
      slug,
      title:ticket.title,
      ticket
    },
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    }),
    options:{
      dropEffect:"move"
    },
    end: (item, monitor) => {/* 
      if(!!monitor.didDrop()) {
        setToSend({
          ...toSend,tickets:[...toSend, item.ticket]
        })
      } else {
        setDropList([...dropList].filter(drop => drop.slug !== item.slug))
      } */
      
      if(!!monitor.didDrop()) {
        markAsPassed(slug,'right')
      } else {
        markAsPassed(slug,'left')
      }
    }
  })

  /* Con isDragging se pueden crear condicionales extra para cuando se está ejecutando la acciónn de arrastre del item, utilizando esta Prop como un booleano */
  return (
    <li 
      ref={drag}
      style={{
        opacity:1,
        cursor:`${isDragging ? 'move' : 'grabbing'} !important`
      }}
    >
      <p>{ticket.title}</p>
      <i className="fas fa-grip-lines-vertical"></i>
    </li>
  )
}
