import React from "react";
import Axios from "axios";

//contexto proveedor de store redux
import { Provider } from "react-redux";

//Router Principal
import AppRouter from "./routers/AppRouter";
import store from "./redux";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

/* @TODO: Eliminar esto al momento de hacer el cambio total de
    a forma en que se hacen peticiones a la API */
Axios.defaults.baseURL = process.env.REACT_APP_API_URL;

export const App = () => {
  return (
    <Provider store={store}>
      <DndProvider backend={HTML5Backend}>
        <AppRouter />
      </DndProvider>
    </Provider>
  );
};
