import initialState from "../state";
import { TYPES } from "../types";

export const reportesReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.reportes:
      return {
        ...state,
        charts: { ...action.payload },
      };

    case TYPES.reportesRango:
      return {
        ...state,
        range: action.payload,
      };

    case TYPES.reportesBorrar:
      return { ...initialState };

    default:
      return state;
  }
};
