import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';

export const OTProductInfoInputsB = ({otForm, setOtForm}) => {
  
  const {observations} = useSelector(state => state.client.ticket_info)
  const { ub1, up_belt_1, wc1, watch_center_1, db1, down_belt_1, 
  ub2, up_belt_2, wc2, watch_center_2, db2, down_belt_2,} = otForm;
  
  const handleChange = (e) => {
    //console.log('estado',e.target.name,e.target.value);
    switch (e.target.name) {
      case 'ub1':
        return setOtForm({
          ...otForm,
          [e.target.name]: !ub1
        });
      case 'wc1':
        return setOtForm({
          ...otForm,
          [e.target.name]: !wc1
        });      
      case 'db1':
        return setOtForm({
          ...otForm,
          [e.target.name]: !db1
        });
      case 'ub2':
        return setOtForm({
          ...otForm,
          [e.target.name]: !ub2
        });
      case 'wc2':
        return setOtForm({
          ...otForm,
          [e.target.name]: !wc2
        });      
      case 'db2':
        return setOtForm({
          ...otForm,
          [e.target.name]: !db2
        });
    
      default:
        return setOtForm({
          ...otForm,
          [e.target.name]: e.target.value
        });
    }
  }

  useEffect(() => {
    if(observations) {
      document.querySelector('#OTProductDetail1').checked = observations.back_superior
      document.querySelector('#OTProductDetail2').checked = observations.back_center
      document.querySelector('#OTProductDetail3').checked = observations.back_bottom
      document.querySelector('#OTProductDetail4').checked = observations.front_superior
      document.querySelector('#OTProductDetail5').checked = observations.front_center
      document.querySelector('#OTProductDetail6').checked = observations.front_bottom
    }
  },[observations])
  return (
    <>
    <fieldset className='ot_product_detail_fieldset'>
      <legend>Reverso:</legend>
        <div className='ot_warranty_date'>
          
          <label 
            htmlFor='OTProductDetail1'
            className='ot_has_warranty detail_1'
          >
            <input
              id='OTProductDetail1'
              onChange={ handleChange }
              name='ub1'
              type='checkbox'
              value={ub1}
            />
            <small>Correa superior</small>
          </label>
          <input 
            autoComplete='off'
            className='panel_ot_create_input product_detail'
            disabled={!ub1}
            name='up_belt_1'
            onChange={ handleChange }
            placeholder='Detalle de correa'
            type='text'
            value={up_belt_1}
          />

        </div>
        
        <div className='ot_warranty_date'>
          
          <label 
            htmlFor='OTProductDetail2'
            className='ot_has_warranty detail_2'
          >
            <input
              id='OTProductDetail2'
              onChange={ handleChange }
              name='wc1'
              type='checkbox'
              value={wc1}
            />
            <small>Parte central</small>
          </label>
          <input 
            autoComplete='off'
            className='panel_ot_create_input product_detail'
            disabled={!wc1}
            name='watch_center_1'
            onChange={ handleChange }            
            placeholder='Detalle de reloj'
            type='text'
            value={watch_center_1}
          />

        </div>
        
        <div className='ot_warranty_date'>
          
          <label 
            htmlFor='OTProductDetail3'
            className='ot_has_warranty detail_3'
          >
            <input
              id='OTProductDetail3'
              onChange={ handleChange }
              name='db1'
              type='checkbox'
              value={db1}
            />
            <small>Correa inferior</small>
          </label>
          <input             
            autoComplete='off'
            className='panel_ot_create_input product_detail'
            disabled={!db1}
            name='down_belt_1'
            onChange={ handleChange } 
            placeholder='Detalle de correa'           
            type='text'
            value={down_belt_1}
          />

        </div>
      </fieldset>

      <fieldset className='ot_product_detail_fieldset'>
        <legend>Frente:</legend>
        <div className='ot_warranty_date'>
          
          <label 
            htmlFor='OTProductDetail4'
            className='ot_has_warranty detail_4'
          >
            <input
              id='OTProductDetail4'
              onChange={ handleChange }
              name='ub2'
              type='checkbox'
              value={ub2}
            />
            <small>Correa superior</small>
          </label>
          <input 
            autoComplete='off'
            className='panel_ot_create_input product_detail'
            disabled={!ub2}
            name='up_belt_2'
            onChange={ handleChange }
            placeholder='Detalle de correa'
            type='text'
            value={up_belt_2}
          />

        </div>
        
        <div className='ot_warranty_date'>
          
          <label 
            htmlFor='OTProductDetail5'
            className='ot_has_warranty detail_5'
          >
            <input
              id='OTProductDetail5'
              onChange={ handleChange }
              name='wc2'
              type='checkbox'
              value={wc2}
            />
            <small>Parte central</small>
          </label>
          <input 
            autoComplete='off'
            className='panel_ot_create_input product_detail'
            disabled={!wc2}
            name='watch_center_2'
            onChange={ handleChange }            
            placeholder='Detalle de reloj'
            type='text'
            value={watch_center_2}
          />

        </div>
        
        <div className='ot_warranty_date'>
          
          <label 
            htmlFor='OTProductDetail6'
            className='ot_has_warranty detail_6'
          >
            <input
              id='OTProductDetail6'
              onChange={ handleChange }
              name='db2'
              type='checkbox'
              value={db2}
            />
            <small>Correa inferior</small>
          </label>
          <input             
            autoComplete='off'
            className='panel_ot_create_input product_detail'
            disabled={!db2}
            name='down_belt_2'
            onChange={ handleChange } 
            placeholder='Detalle de correa'           
            type='text'
            value={down_belt_2}
          />

        </div>
      </fieldset>
    </>
  )
}
