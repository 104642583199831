import axios from "axios";
import { isLoading, isNotLoading } from "./ui";

/**
 * Función para realizar cualquier tipo de request
 * pasándole un Object con las siguientes propiedades
 * @param {String} method 
 * @param {String} url 
 * @param {JSON | Object | FormData} data 
 * @param {Boolean} needsToBeStored 
 * @param {String} storeType 
 * @param {Object} headers 
 * @returns {Promise}
 */

const makeRequest = ({ method, url, data, needsToBeStored = false, storeType, headers }) => {
    return async(dispatch) => {
        dispatch( isLoading() );
        /* console.log({ method, url, data, headers }) */
        return await axios({ method, url, data, headers })
          .then(res => {
            typeof method === 'string' && needsToBeStored && dispatch(setInStore(storeType, res.data.data))
            dispatch( isNotLoading() );
            return { message: 'La acción fue ejecutada con éxito', response : res.data.message}
          })
          .catch(err => {
            //alert('No se pudo completar la acción')
            dispatch( isNotLoading() );
            return { message: 'La acción no pudo ser ejecutada', response : err.response.data.message}
          })
    }
}

const setInStore = (type, payload = null) => ({ type, payload})
export default makeRequest
