import React from 'react'
import { useSelector } from 'react-redux';

export const Inputs = ({handleChange, otForm}) => {
  const {ticket_info} = useSelector(state => state.client) 
  const { type:t, brand, model, plan, diagnostic, observations, attender } = otForm;

  return (
    <>
      <div className='panel_ot_create_input_datalist'>
        <input 
          autoComplete='off'
          className='panel_ot_create_input'
          list='ot_brand'
          name='brand'
          onChange={ handleChange }
          placeholder='Marca'
          type='text'
          value={ brand }
        />
        <datalist
          id='ot_brand'
        >
          { (ticket_info && ticket_info.brands) && (ticket_info.brands.filter(brand => brand.type === t)).map(brand => (
            <option key={brand.id} value={brand.name}>{brand.name}</option>
          )) }
        </datalist>
      </div>

      <input
        autoComplete='off' 
        className='panel_ot_create_input'
        name='model'
        onChange={ handleChange }
        placeholder='Modelo'
        type='text'
        value={ model }
      />
      
      <div className='panel_ot_create_input_datalist'>
        <input
          autoComplete='off' 
          className='panel_ot_create_input'
          list='ot_plan'
          name='plan'
          onChange={ handleChange }
          placeholder='Plan - Opcional'
          type='text'
          value={ plan }
        />
        <datalist
          id='ot_plan'
        >
          <option value="Ninguno">Ninguno</option>
          { (ticket_info && ticket_info.warranties) && ticket_info.warranties.map(warranty => (<option key={warranty.id} value={warranty.name}>{warranty.name}</option>
          )) }
        </datalist>
      </div>
      
      <textarea
        autoComplete='off'
        className='panel_ot_textarea_diagnostic'
        name='diagnostic'
        onChange={ handleChange }
        placeholder='Pre-Diagnóstico'
        required
        value={ diagnostic }
      ></textarea>

      <textarea
        autoComplete='off'
        className='panel_ot_textarea_observations'
        name='observations'
        onChange={ handleChange }
        placeholder='Observaciones externas'
        required
        value={ observations }
      ></textarea>
      
      <input
          autoComplete='off' 
          className='panel_ot_create_input'
          name='attender'
          onChange={ handleChange }
          placeholder='Atendido por...'
          type='text'
          value={ attender }
      />
    </>
  )
}
