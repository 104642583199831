export const commentDate = (fecha) => {
  let d = new Date(fecha);
  let year = d.getFullYear(),
    month = d.getMonth() + 1,
    dt = d.getDate(),
    hr = d.getHours(),
    mn = d.getMinutes();
  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }
  if (hr < 10) {
    hr = "0" + hr;
  }
  if (mn < 10) {
    mn = "0" + mn;
  }
  let fullDate = year + "-" + month + "-" + dt + " " + hr + ":" + mn;

  return fullDate;
};

export const days = (number) => {
  /**Regresa la cantidad ingresada de días en milisegundos */
  return number * 86400000;
};

export const formatDateWithTime = (fecha) => {
  let d = new Date(Date.parse(fecha));
  let year = d.getFullYear() + "\n",
    month = d.getMonth() + 1,
    dt = d.getDate(),
    hr = d.getHours(),
    mn = d.getMinutes();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }
  if (hr < 10) {
    hr = "0" + hr;
  }
  if (mn < 10) {
    mn = "0" + mn;
  }

  //console.log("día y hora de creación: ", dt + '-' + month + '-' + year + hr + ':' + mn + 'hrs');
  return dt + "-" + month + "-" + year + "\n" + hr + ":" + mn + "hrs";
};

export const justDate = (fecha, formatoDB = false) => {
  let d = new Date(Date.parse(fecha));
  let year = d.getFullYear(),
    month = d.getMonth() + 1,
    dt = d.getUTCDate();
  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }

  if (formatoDB) return year + "-" + month + "-" + dt;

  return dt + "-" + month + "-" + year;
};

export const stringForDate = (stringDate) => {
  const fullDate = stringDate,
    yr = fullDate.slice(6, 10),
    mt = fullDate.slice(3, 5),
    day = fullDate.slice(0, 2),
    hr = fullDate.slice(11, 13),
    mn = fullDate.slice(14, 16);
  return Date(yr, mt, day, hr, mn);
};

export let commentClass = (tipo) => {
  if (tipo) {
    if (tipo === "Cliente") {
      return "seccion-detalle-comentario-usuario-actual";
    } else if (tipo === "Agente") {
      return "seccion-detalle-comentario-usuario";
    } else if (tipo === "Interno") {
      return "seccion-detalle-comentario-usuario interno";
    } else {
      return "seccion-detalle-comentario-usuario interno";
    }
  } else {
    return "";
  }
};

export const toTheTop = () => {
  return window.scrollTo({ top: 0, behavior: "smooth" });
};

export const hasNumber = (string) => /\d/.test(string);

export function onlyUnique(value, index, self) {
  /*Devuelve un array filtrado con valores únicos. Ejemplo de uso: array.filter(onlyUnique)*/
  return self.indexOf(value) === index;
}
