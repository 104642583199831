import { Button, Modal } from 'react-bootstrap'
import React from 'react'
/* 
    setOtForm({...initialState}) */
export const OTDeletionModal = (props) => {
  const toErase = async() =>{
    const {setOtForm,initialState} = props.show.info
    await setOtForm({...initialState})
    props.onHide()
  }

  return (
    <Modal
      show={props.show && props.show.status}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className='ot_confirmation_top'>
        <Modal.Title id="contained-modal-title-vcenter">
          Creación de OT
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='ot_confirmation_body'>
        <h6>Vaciar formulario:</h6>
        <p className='pl-2'>
          Se vaciará toda la información que ingresó en el formulario. Al hacer click en el botón amarillo, usted confirma que quiere realizar esta acción.
        </p>
      </Modal.Body>
      <Modal.Footer className='ot_confirmation_bottom'>
        <Button variant="warning" onClick={toErase}><i className="fas fa-exclamation-triangle"></i> Vaciar información</Button>
        <Button variant="outline-danger" onClick={props.onHide}>Volver</Button>
      </Modal.Footer>
    </Modal>
  )
}
