import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { isCS, isPDV, isWatchmaker } from "../../../helpers/permission";
import { PATHS } from "../../../types/paths";

export const OTNavbar = () => {
  const { ticket_count } = useSelector((state) => state.header);
  const { name: role } = useSelector((state) => state.auth.role);

  return (
    <ul id="ot_navbar" className={isWatchmaker(role) ? "bg-light" : ""}>
      {isCS(role) && (
        <>
          <NavLink
            activeClassName="panel_cliente_tipo_activo"
            to={PATHS.otGeneralTickets}
          >
            OT({ticket_count && ticket_count.ot_general})
          </NavLink>

          <NavLink
            activeClassName="panel_cliente_tipo_activo"
            to={PATHS.otToDeliverTickets}
          >
            A entregar({ticket_count && ticket_count.ot_to_deliver})
          </NavLink>

          <NavLink
            activeClassName="panel_cliente_tipo_activo"
            to={PATHS.otToConfirmTickets}
          >
            A confirmar({ticket_count && ticket_count.ot_to_confirm})
          </NavLink>

          <NavLink
            activeClassName="panel_cliente_tipo_activo"
            to={PATHS.otDistributionTickets}
          >
            Distribución({ticket_count && ticket_count.ot_distribution})
          </NavLink>

          <NavLink
            activeClassName="panel_cliente_tipo_activo"
            to={PATHS.otRepairedTickets}
          >
            Reparados({ticket_count && ticket_count.ot_repaired})
          </NavLink>

          <NavLink
            activeClassName="panel_cliente_tipo_activo"
            to={PATHS.otPendantTickets}
          >
            Pendientes({ticket_count && ticket_count.ot_pendant})
          </NavLink>

          <NavLink
            activeClassName="panel_cliente_tipo_activo"
            to={PATHS.otProductionTickets}
          >
            En producción({ticket_count && ticket_count.ot_production})
          </NavLink>

          <NavLink
            activeClassName="panel_cliente_tipo_activo"
            to={PATHS.otTransitTickets}
          >
            En tránsito({ticket_count && ticket_count.ot_in_transit})
          </NavLink>
        </>
      )}

      {isWatchmaker(role) && (
        <NavLink
          activeClassName="panel_cliente_tipo_activo"
          to={PATHS.otGeneralTickets}
        >
          OT(-Relojero-)
        </NavLink>
      )}
      {isPDV(role) && (
        <>
          <NavLink
            activeClassName="panel_cliente_tipo_activo"
            to={PATHS.otGeneralTickets}
          >
            OT(-PDV-)
          </NavLink>
          <NavLink
            activeClassName="panel_cliente_tipo_activo"
            to={PATHS.otDistributionTickets}
          >
            Distribución
          </NavLink>
          <NavLink
            activeClassName="panel_cliente_tipo_activo"
            to={PATHS.otToDeliverTickets}
          >
            Para retirar
          </NavLink>
        </>
      )}

      {isCS(role) /* || isPDV(role) */ && (
        <NavLink
          activeClassName="panel_cliente_tipo_activo"
          to={PATHS.otVouchers}
        >
          Vouchers
        </NavLink>
      )}
    </ul>
  );
};
