import React from 'react'
import { OTDragCard } from './OTDragCard'

export const OTDropFrom = ({ticketList}) => {
  return (
    <ul>
      { (ticketList && ticketList.length > 0) 
      && ticketList
      .filter(ticket => ticket.aux_status === 'wip')
      .map((ticket,index) => {
        return (
          <OTDragCard
            ticket={ticket} 
            key={index}
          />
        ) 
      })}
    </ul>
  )
}
