import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { loadRma, updateRma } from '../../../actions/ot/ot-detail'
import { otGetInfo } from '../../../actions/ot/ot-get'

import { isCS, isPDV } from '../../../helpers/permission'

export const OTSimple = (props) => {
  const {name:role} = useSelector(state => state.auth.role)
  const [error, setError] = useState({status:false, message:''})
  const dispatch = useDispatch()
  const {pathname} = useLocation()
  const handleClose = async(e) => {
    if((isCS(role) && props.status.name === 'Para entrega cliente en CS') 
    || (isPDV(role) && props.status.name === 'Para entrega cliente en PDV')) {
      let fd = new FormData()
      fd.append('action','close')
      await dispatch(updateRma(props.slug,fd))
      if(pathname.includes('panel/ot/deliver')) {
        if(isCS(role)) {
            dispatch(otGetInfo('ready-client-deliver-in-cs'));
            return props.onHide()
        } else if(isPDV(role)) {
            dispatch(otGetInfo('ready-client-deliver-in-pdv'));
            return props.onHide()
        } 
      }
      await dispatch(loadRma(props.slug))
      return props.onHide()
    }
  }
  return (
    <Modal
      show={props.show && props.show.status}
      onHide={props.onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='ot_watchmaker_repair_modal'
    >
      <Modal.Header closeButton className='ot_watchmaker_repair_modal_top'>
        <Modal.Title id="contained-modal-title-vcenter watchmaker_to_derive_title">
          <h6>Cerrar OT</h6>
          <span>OT #<strong>{props.slug}</strong></span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='ot_watchmaker_repair_modal_body m-0'>
        <div className='ot_watchmaker_input_to_derive'>
          {
            error.status 
            ? <small className='text-danger'>{error.message}</small>
            : <small>Si desea actualizar el estado de la OT como "Entregado" y cerrarla, haga click en el botón azul</small>
          }
        </div>
      </Modal.Body>
      <Modal.Footer className='ot_watchmaker_repair_modal_bottom ot_watchmaker_derive_buttons'>
        <Button
          variant="info" 
          onClick={handleClose}
        >
          <i className="fas fa-clipboard-check"></i>Enviar ahora
        </Button>

        <Button 
          variant="danger" 
          onClick={props.onHide}
        >
          Volver
        </Button>

      </Modal.Footer>
    </Modal>
  )
}
