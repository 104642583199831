import React, { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export const PrivateRouter = ({
    isAuth, 
    component: Component,
    ...rest
}) => {
   /*  const {user, role} = useSelector(state => state.auth);

    const aux = role?.type ?? ''

    useEffect(() => {
        console.log(aux)
        if(aux && aux === 'admin'){
            return window.location.href = `http://localhost:3001/panel?token=${localStorage.getItem('token')}`
        }
    }, [aux]) */
    return (
        <Route
            {...rest}
            component={(props) => (
                (isAuth)
                    ? <Component {...props} />
                    : <Redirect to={{
                        pathname:'/auth',
                        state:{
                            from: props.location
                        }
                    }} />
            )}
        />
    )
}

PrivateRouter.propTypes = {
    isAuth: PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired
}
