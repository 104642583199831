import { TYPES } from "../../../../types/types"

export const postRequest = { 
  method:'post', 
  url:'api/voucher/create', 
  data: {}, 
  storeType:'', 
  headers:{
    'Authorization':'Bearer ' + localStorage.getItem('token')
  } 
}

export const getRequest = { 
  method:'get', 
  url:'api/voucher/create', 
  data: {}, 
  storeType: TYPES.setVoucherOTInfoToCreate, 
  needsToBeStored: true,
  headers:{
    'Authorization':'Bearer ' + localStorage.getItem('token')
  } 
}
