import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { otInfoSearch } from '../../../actions/ot/ot-search';
import { PATHS } from '../../../types/paths';
import { OTSearchStatus } from './OTSearchStatus';


export const OTWithdrawalForm = () => {
  const dispatch = useDispatch(); 
  const history = useHistory();

  const [error, setError] = useState({
      status:false,
      message:''
  });

  const initialState = {ot:'', ext:''}
  const [form, setForm] = useState(initialState);

  const {ot, ext} = form;

  const handleChange = e => {
    setForm({
        ...form,
        [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async(e) => {
      e.preventDefault();
/*      console.log('éxito')
 
      const entriesFilter = Object.entries(form).filter( entry => entry[1]);
      if(entriesFilter.length < 1) {
          return setError({
              status:true,
              message:'Debe completar los campos correspondientes'
          })
      }
      if(!Number(dni) || !Number(ot)) {
          return setError({
              status:true,
              message:'Complete los campos de manera correcta'
          })
      }
      setError({status:false, message:'Ingrese DNI y OT para ver el estado de reparación '})
      
    let   
        fd = new FormData(),
        approvalForDispatch = 0
      
      for(let i = 0; i < entriesFilter.length; i++) {
        fd.append(`${entriesFilter[i][0]}`,entriesFilter[i][1])
        approvalForDispatch++
      }

      if( approvalForDispatch === 2 ) {
        return dispatch(otInfoSearch(fd))
      }
      
      return setError({status:true, message:'Ha ocurrido un error al buscar la información '}) */
  }
  return (
      <form className='ot_search_form'>
          <div>
              <p className="panel_cliente_crear_ticket_nuevo">Retiro de OT</p>
              <small className={ 'panel_cliente_crear_ticket_aclaracion ' + (error.status && 'error_color')}>
                  {
                      error.status ? error.message : 'Ingrese el número de OT y elija una ubicación para confirmar donde se retirará finalmente'
                  }
              </small>
              <div className="panel_cliente_crear_ticket_inputs">
                
                <small>número de ot</small>
                <input
                    required
                    autoComplete="off"
                    type='text' 
                    list="panel_cliente_tema"
                    name="ot"
                    value={ot}
                    onChange={handleChange}
                    placeholder="OT(solo números)"
                />
                <small>ubicación para retiro</small>
                <input
                    required
                    autoComplete="off"
                    type='text' 
                    list="exporting-report-extension"
                    name="ext"
                    value={ext}
                    onChange={handleChange}
                    placeholder="Elija una ubicación"
                />
                <datalist id="exporting-report-extension">
                  <option value="Customer Service 1">Customer Service 1</option>
                  <option value="Customer Service 2">Customer Service 2</option>
                  <option value="Customer Service 3">Customer Service 3</option>
                  <option value="PDV 1">PDV 1</option>
                  <option value="PDV 2">PDV 2</option>
                  <option value="PDV 3">PDV 3</option>
                </datalist>
              </div>
          </div>
          <div className='ot_button_group_1'>
            <button
            className='ot_search_button blue2 text-white'
            onClick={handleSubmit}
          > 
            <small>
                <i className="fas fa-sign-out-alt"></i>
                Actualizar
            </small>
          </button>
          {/* <button
            onClick={() => history.push(PATHS.otCreateTickets)}
            className='ot_create_button yellow4 text-white'
          > 
            <i className="far fa-chart-bar"></i>
            <small>A gráficas</small>
          </button> */}
            
          </div>
          <br />
      </form>
  )
}


