import { TYPES } from "../types/types";

const initialState = {
  client_info:{},
  ticket_info:{},
  ot_search_info:[]
}

export const clientReducer = (state = initialState, action) => {

    switch (action.type) {

      case TYPES.setOTClientInfo:
        return { ...state, client_info: { ...action.client } }

      case TYPES.setOTTicketFormInfo:
        return { ...state, ticket_info: { ...action.ticketInfo } }
      
      case TYPES.setClientRMASearchInfo:
        return { ...state, ot_search_info: [...action.rma] }

      case TYPES.deleteClientRMASearchInfo:
        return { ...state, ot_search_info: [] }
      
      case TYPES.deleteClientInfo:
        return { ...initialState }

        default:
            return state;
    }
}