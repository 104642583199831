import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { otDeleteInfoSearch, otInfoSearch } from '../../../actions/ot/ot-search';
import { isPDVorCS } from '../../../helpers/permission';
import { PATHS } from '../../../types/paths';
import { OTSearchStatus } from './OTSearchStatus';

export const OTSearchForm = () => {
  const dispatch = useDispatch(); 
  const history = useHistory();
  const {name:role} = useSelector(state => state.auth.role)
  const {ot_search_info:list} = useSelector(state => state.client)

  const [error, setError] = useState({
      status:false,
      message:''
  });

  const initialState = {dni:'', ot:''}
  const [form, setForm] = useState(initialState);

  const {dni, ot} = form;

  const handleChange = e => {
    setForm({
        ...form,
        [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async(e) => {
      e.preventDefault();
      dispatch(otDeleteInfoSearch())

      const entriesFilter = Object.entries(form).filter( entry => entry[1]);
      if(entriesFilter.length < 1) {
          return setError({
              status:true,
              message:'Debe completar los campos correspondientes'
          })
      }
      if(!Number(dni) && !Number(ot)) {
          return setError({
              status:true,
              message:'Complete los campos de manera correcta'
          })
      }
      setError({status:false, message:'Ingrese DNI y OT para ver el estado de reparación '})
      
    let   
        fd = new FormData(),
        approvalForDispatch = 0
              
      for(let i = 0; i < entriesFilter.length; i++) {
        fd.append(`${entriesFilter[i][0]}`,entriesFilter[i][1])
        approvalForDispatch++
      }

      if( approvalForDispatch >= 1 ) {
        return dispatch(otInfoSearch(fd,setError))
      }
      
      return setError({status:true, message: 'Ha ocurrido un error al buscar la información '})
      
  }
  return (
      <form className='ot_search_form'>
          <div>
              <p className="panel_cliente_crear_ticket_nuevo">Buscar OT</p>
              <small className={ 'panel_cliente_crear_ticket_aclaracion ' + (error.status && 'error_color')}>
                  {
                      error.status ? error.message : 'Ingrese DNI y OT para ver el estado de reparación '
                  }
              </small>
              <div className="panel_cliente_crear_ticket_inputs">
                  <input 
                      required
                      autoComplete="off"
                      type='text' 
                      name="dni"
                      value={dni}
                      onChange={handleChange}
                      placeholder="dni"
                  />
                  <input
                      required
                      autoComplete="off"
                      type='text' 
                      list="panel_cliente_tema"
                      name="ot"
                      value={ot}
                      onChange={handleChange}
                      placeholder="ot"
                  />
                  <OTSearchStatus 
                    list={list}
                    role={role}
                  />

              </div>
          </div>
          <div className='ot_button_group_1'>
            <button
            className='ot_search_button green'
            onClick={handleSubmit}
          > 
            <small>
                <i className="fas fa-search"></i>
                Buscar OT
            </small>
          </button>
          {
            isPDVorCS(role) &&
            <button
              onClick={() => history.push(PATHS.otCreateTickets)}
              className='ot_create_button yellow3'
            > 
              <div>+</div> 
              <small>Crear OT</small>
            </button>
          }
            
          </div>
          <br />
      </form>
  )

}
