import Tippy from '@tippyjs/react';
import React, { createContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { otDeleteDistInfo, otGetInfo, otGetToAssignInfo } from '../../../../actions/ot/ot-get';
import { otSendOtListToUpdate } from '../../../../actions/ot/ot-update';
import { uploadTickets } from '../../../../actions/tickets';
import { isCS, isPDV } from '../../../../helpers/permission';

import { OTDropFrom } from './OTDropFrom';
import { OTDropTo } from './OTDropTo';

export const OTDistToSendContext = createContext({})

export const OTMassiveDistribution = () => {
  const {name:role} = useSelector(state => state.auth.role)
  const {update_info:role_users} = useSelector(state => state.ot)
  const {tickets} = useSelector(state => state.storage)

  const dispatch = useDispatch()
  const [distType, setDistType] = useState('dist')
  const [error, setError] = useState({
    status:false,message:''
  })
  const [toSend, setToSend] = useState({
    tickets:[...tickets], destination:''
  })

  const [filterText, setFilterText] = useState('')
  /* const filterOTForMassiveDist = (search) => {
    const keywords = {'filter': search}
    switch (distType) {
      case 'dist':
        return dispatch(otGetInfo('distribution', keywords))
      case 'receive':
        return dispatch(otGetInfo('in-transit', keywords))
      case 'tr-to-dl':
        return dispatch(otGetInfo('transit-to-deliver', keywords))
      case 'to-cs':
        return dispatch(otGetInfo('to-cs', keywords))
      case 'from-cs':
        return dispatch(otGetInfo('from-cs', keywords))
      default:
        return setError({status:true, message:'Hay un problema con la petición hacia el servidor, por favor, intente de nuevo'})
    }
  } */
  const filterOTForMassiveDist = (search) => {
    let flag = true;
    if(search.length) {
      setError({ status:false,message:'' })
      const auxTickets = [...toSend.tickets].map(ticket => {
        if(ticket.title.includes(search)) {
          flag = false
          return {...ticket, aux_status: 'passed'}
        } else {
          return ticket
        }
      })
      if(flag) {
        return setError({status:true, message:'El número ingresado no corresponde con ningún OT de la lista'})
      } else {
        setError({ status:false,message:'' })
        setToSend({...toSend, tickets: auxTickets})
        return setFilterText('')
      }
    } else {
      return setError({status:true, message:'Debe ingresar en el filtro una serie de números que corresponda con los OT disponibles'})
    }
  }
  const resetFilter = () => {
    setError({ status:false,message:'' })
    const auxTickets = [...toSend.tickets].map(ticket => ticket.aux_status.includes('passed') ? {...ticket, aux_status: 'wip'} : ticket )
    setToSend({...toSend, tickets: auxTickets})
  }

  const markAsPassed = (_slug, flag) => {
    let mark = 'rma';
    let ticket = toSend.tickets.filter( ticket => (ticket.rma && ticket.rma.slug === _slug))
    if(ticket.length < 1) {
      ticket = toSend.tickets.filter( ticket => ticket.slug === _slug)
      mark = 'ticket'
    } 
    
    if (flag === 'right') {
      ticket[0].aux_status = 'passed'
    } else if (flag === 'left') {
      ticket[0].aux_status = 'wip'
    } 

    if (mark === 'ticket') {
      return setToSend({ ...toSend, tickets : toSend.tickets.filter(ticket => ticket.slug !== _slug).concat(ticket[0])})
    } else {
      return setToSend({ ...toSend, tickets : toSend.tickets.filter(ticket => ticket.rma.slug !== _slug).concat(ticket[0])})
    }
  }

  const handleSend = async(e) => {
    e.preventDefault()
    let 
      itemsToSend,roleUserId,fd;
    itemsToSend = toSend.tickets.filter(ticket => ticket.aux_status === 'passed').map(ticket => !!ticket.rma ? ticket.rma.slug : false)
    if (itemsToSend.length < 1) {
      return setError({status:true, message:'Es necesario tener al menos un OT en el panel derecho para poder distribuirlo'})
    }
    if((distType === 'dist' || distType === 'to-cs') && !toSend.destination) {
      return setError({status:true, message:'Es necesario elegir hacia dónde se quiere enviar el OT'})
    }

    if(distType === 'dist' || distType === 'to-cs') {
      roleUserId = role_users.filter(user => user.username === toSend.destination)
      if(roleUserId.length < 1) {
        return setError({status:true, message:'Es necesario elegir correctamente hacia dónde se quiere enviar el OT'})
      }
    }

    setError({ status:false,message:'' })

    fd = new FormData();
    fd.append('ot_list',itemsToSend)
    /* console.log(itemsToSend,toSend.tickets) */
    //console.log(distType)
    switch (distType) {
      case 'dist':
        fd.append('watchmaker',roleUserId[0].id)
        return dispatch(otSendOtListToUpdate(fd,'ot-to-technical'))
      case 'receive':
        return dispatch(otSendOtListToUpdate(fd,'receive-in-cs'))
      case 'tr-to-dl':
        return dispatch(otSendOtListToUpdate(fd,'transit-to-deliver'))
      case 'to-cs':
        fd.append('cs',roleUserId[0].id)
        return dispatch(otSendOtListToUpdate(fd,'ot-to-cs'))
      case 'from-cs':
        return dispatch(otSendOtListToUpdate(fd,'from-cs'))
      default:
        setError({status:true, message:'Hay un problema con la petición hacia el servidor, por favor, intente de nuevo'})
    }

  }

  const handleToTechnical = e => {
    setToSend({...toSend, destination:e.target.value})
  }

  const distributionType = e => {
    const aux = e.target.value
    setDistType(aux)
    dispatch(otDeleteDistInfo())
    switch (aux) {
      case 'dist':
        return dispatch(otGetInfo('distribution'))
      case 'receive':
        return dispatch(otGetInfo('in-transit'))
      case 'tr-to-dl':
        return dispatch(otGetInfo('transit-to-deliver'))
      case 'to-cs':
        return dispatch(otGetInfo('to-cs'))
      case 'from-cs':
        return dispatch(otGetInfo('from-cs'))
      default:
        return setError({status:true, message:'Hay un problema con la petición hacia el servidor, por favor, intente de nuevo'})
    }
  }

  useEffect(() => {
    if(isCS(role)) {
      dispatch(otGetToAssignInfo('ot-to-technical'))
      setDistType('dist')
    } else if (isPDV(role)) {
      dispatch(otGetToAssignInfo('ot-to-cs'))
      setDistType('to-cs')
    }
  }, [dispatch,role])

  useEffect(() => {
    if(tickets.length > 0) {
      setToSend({tickets : [...tickets], destination:''})
    } else {
      setToSend({tickets : [], destination:''})
    }
  },[tickets])
  return (
    <OTDistToSendContext.Provider value={{markAsPassed}}>
      <form className='ot_massive_distribution'>
        <div className='ot_distribution_top'>
          <div className='ot_distribution_text'>
            {
              error.status 
              ? <p className='text-danger'>{error.message}</p>
              :<p>Arrastre las OT que requieran ser distribuidas, al espacio de la derecha, seleccione el tipo de distribución de OT y haga click en <strong>enviar</strong> </p>
            }
          </div>
          <div className='ot_distribution_options'>
            <div className='ot_distribution_select'>
              <select
                onChange={distributionType}
              >
                {
                isCS(role) && 
                <>
                  <option value='dist'>Distribución</option>
                  <option value='receive'>Recepción en CS</option>
                  <option value='tr-to-dl'>Despacho cliente</option>
                </>}
                {
                isPDV(role) && 
                <>
                  <option value='to-cs'>Envío a CS</option>
                  <option value='from-cs'>Recepción desde CS</option>
                </>}
              </select>
            </div>
            <div className='ot_distribution_send'>
              <button
                onClick={handleSend}
              >
                <span>
                  Enviar
                </span>
              </button>
            </div>
          </div>
        </div>
        
        <div className='ot_distribution_dnd_group'>
          <div className='ot_distribution_dnd_start_group'>
            <div className='ot_distribution_destination filter-ot'>
              <div className='aux_container'>
                <small>Filtro de OT</small>
                <input
                  autoComplete='off' 
                  className='ot_dist_filter'
                  list='ot_destination_list'
                  name='filterText'
                  placeholder='Filtrado por número'
                  type='text'
                  value={ filterText }
                  onChange={e => setFilterText(e.target.value)}
                />
              </div>
              <Tippy content='Reiniciar filtro' placement='top'>
                <button 
                  type='button'
                  className='erase-button red1'
                  onClick={resetFilter}
                >
                  <i className="fas fa-undo-alt"></i>
                </button>
              </Tippy>
              <button 
                type='button'
                className='filter-button green'
                onClick={() => filterOTForMassiveDist(filterText)}
              >
                <span>Buscar</span>
                <i className='fas fa-search'></i>
              </button>
            </div>
            <div className='ot_distribution_dnd_start'>
              <OTDropFrom 
                ticketList={toSend.tickets}
                /* filterText={filterText} */
              />
            </div>
          </div>
          <i className="fas fa-exchange-alt"></i>
          <div className='ot_distribution_dnd_finish_group'>
            {(distType === 'dist' || distType === 'to-cs') && <div className='ot_distribution_destination'>
              <small>Seleccione el destino</small>
              <input
                autoComplete='off' 
                className='ot_destination'
                list='ot_destination_list'
                name='destination'
                placeholder='Destino para OT'
                type='text'
                value={ toSend.destination }
                onChange={handleToTechnical}
              />
              <datalist
                id='ot_destination_list'
              > 
                <option value="Ninguno">Ninguno</option>
                {role_users && role_users.map((watchmaker,index) => {
                  return (
                    <option
                      value={watchmaker.username}
                      key={index}
                    >
                      {watchmaker.username + "(" + watchmaker.name + ")"}
                    </option>
                  )
                })}
              </datalist>
            </div>}
            <div className='ot_distribution_dnd_finish'>
              <OTDropTo 
                ticketList={toSend.tickets}
              />
            </div>
          </div>
        </div>
      </form>
    </OTDistToSendContext.Provider>
  )
}
