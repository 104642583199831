import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startLogin } from '../actions/auth'
import { authError, noAuthError } from '../actions/ui'
import { useForm } from '../hooks/useForm'

import logo from '../logo.png'
import { Loader } from './Loader/Loader'

export const Login = () => {
	const dispatch = useDispatch()
	const { loading } = useSelector((state) => state.ui)
	const [error, setError] = useState({ status: false, message: '' })

	const [value, handleInputChange] = useForm({
		username: '',
		password: '',
	})

	const { username, password } = value

	const handleSubmit = (e) => {
		e.preventDefault()
		//console.log('validacion')
		//validación de datos por mejorar
		if (username.trim().length < 4 || password.trim().length < 4) {
			setError({
				status: true,
				message: 'Por favor, verifique los datos ingresados',
			})
			return false
		}

		setError({ status: false, message: '' })
		dispatch(startLogin({ username, password }))
	}

	return (
		<div className='contenedor'>
			<img className='contenedor-logo' src={logo} alt='logo C-team' />

			<form className='contenedor-form' onSubmit={handleSubmit}>
				<div className='contenedor-datos'>
					<h2>Inicio de sesión</h2>

					{error.status ? (
						<small className='errorAuthMessage'>{error.message}</small>
					) : (
						<small className='errorMessage'>
							Ingrese sus datos para acceder
						</small>
					)}

					<div className='login-username'>
						<small>
							<label htmlFor='login-username'>Usuario</label>
						</small>
						<input
							type='text'
							name='username'
							id='login-username'
							placeholder='Usuario'
							value={username}
							onChange={handleInputChange}
							autoComplete='off'
						/>
					</div>

					<div className='login-password'>
						<small>
							<label htmlFor='login-password'>Contraseña</label>
						</small>
						<input
							type='password'
							name='password'
							id='login-password'
							placeholder='Contraseña'
							value={password}
							onChange={handleInputChange}
						/>
					</div>

					{loading ? (
						<Loader small={true} />
					) : (
						<button type='submit' className='login-button'>
							Ingresar
						</button>
					)}
				</div>
			</form>
		</div>
	)
}
