import { TYPES } from "../types/types";
const initialState = {
    user:{},
    role:{
        name:'',
        type:''
    }
}

export const authReducer = ( state = {}, action ) => {

    switch (action.type) {

        case TYPES.login:
            return {
                ...state,
                user: {...action.payload.user},
                role: {
                    name:action.payload.role.name,
                    type:action.payload.role.type,
                },
            };

        case TYPES.logout:
            return {
                ...initialState
            };
    
        default:
            return state;
    }

}

/*{
    uid: 'dfvbnmrtyui67893748324',
    name: 'Cristian',
}*/