import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { otInfoSearch } from '../../../actions/ot/ot-search';
import { PATHS } from '../../../types/paths';
import { OTSearchStatus } from './OTSearchStatus';

export const OTReportsForm = () => {
  const dispatch = useDispatch(); 
  const history = useHistory();

  const [error, setError] = useState({
      status:false,
      message:''
  });

  const initialState = {dni:'', ext:'.csv'}
  const [form, setForm] = useState(initialState);

  const {dni, ext} = form;

  const handleChange = e => {
    setForm({
        ...form,
        [e.target.name]: e.target.value
    })
  }

  const handleSubmit = async(e) => {
      e.preventDefault();
/*      console.log('éxito')
 
      const entriesFilter = Object.entries(form).filter( entry => entry[1]);
      if(entriesFilter.length < 1) {
          return setError({
              status:true,
              message:'Debe completar los campos correspondientes'
          })
      }
      if(!Number(dni) || !Number(ot)) {
          return setError({
              status:true,
              message:'Complete los campos de manera correcta'
          })
      }
      setError({status:false, message:'Ingrese DNI y OT para ver el estado de reparación '})
      
    let   
        fd = new FormData(),
        approvalForDispatch = 0
      
      for(let i = 0; i < entriesFilter.length; i++) {
        fd.append(`${entriesFilter[i][0]}`,entriesFilter[i][1])
        approvalForDispatch++
      }

      if( approvalForDispatch === 2 ) {
        return dispatch(otInfoSearch(fd))
      }
      
      return setError({status:true, message:'Ha ocurrido un error al buscar la información '}) */
  }
  return (
      <form className='ot_search_form'>
          <div>
              <p className="panel_cliente_crear_ticket_nuevo">Reportes OT</p>
              <small className={ 'panel_cliente_crear_ticket_aclaracion ' + (error.status && 'error_color')}>
                  {
                      error.status ? error.message : 'Elija un rango de fechas, un formato de archivo para crear el reporte y luego exportarlo'
                  }
              </small>
              <div className="panel_cliente_crear_ticket_inputs">
                <small>fecha de inicio</small>
                <input 
                  required
                  autoComplete="off"
                  type='date' 
                />
                <small>fecha de fin</small>
                <input 
                  required
                  autoComplete="off"
                  type='date' 
                />
                <small>formato de archivo</small>
                <input
                    required
                    autoComplete="off"
                    type='text' 
                    list="exporting-report-extension"
                    name="ext"
                    value={ext}
                    onChange={handleChange}
                    placeholder="Extensión"
                />
                <datalist id="exporting-report-extension">
                  <option value=".csv">.csv</option>
                  <option value=".xlsx">.xlsx</option>
                  <option value=".pdf">.pdf</option>
                </datalist>
              </div>
          </div>
          <div className='ot_button_group_1'>
            <button
            className='ot_search_button blue2 text-white'
            onClick={handleSubmit}
          > 
            <small>
                <i className="far fa-file-alt"></i>
                Exportar
            </small>
          </button>
          <button
            onClick={() => history.push(PATHS.otCreateTickets)}
            className='ot_create_button yellow4 text-white'
          > 
            <i className="far fa-chart-bar"></i>
            <small>A gráficas</small>
          </button>
            
          </div>
          <br />
      </form>
  )

}
