import Axios from "axios";
import { TYPES } from "../../types/types";

export const loadOtModalComments = slug => {
  return async(dispatch) => {
        await Axios.get(`api/rma/${slug}/comments`, {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then(res => {
          dispatch(otModalComments(res.data.comments));
        })
        .catch(err => {
          //console.log(err)
            //alert('Falló la búsqueda de información sobre OT, intente nuevamente: ', err);
          console.warn('Falló la búsqueda de información sobre comentarios de OT para PDV, intente nuevamente: ', err)
        })
  }
}

export const otModalComments = comments => ({
    type: TYPES.otModalComments,
    payload: comments
})

export const deleteOtModalComments = () => ({
    type: TYPES.deleteOtModalComments,
})