export const modalState = {
  modalShow: false,
}

export const PDFModalState = {
  modalShow: false,
}

export const editModalState = {
  modalShow: false,
}
