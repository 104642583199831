import React from 'react'
import { Inputs } from './A_side/Inputs'

export const OTProductInfoInputsA = ({otForm, setOtForm}) => {
  
  const handleChange = (e) => {
    //console.log(e.target.name, e.target.name === 'type' && otForm.brand)
    if(e.target.name === 'type') {
      setOtForm({
        ...otForm,
        brand:'',
        [e.target.name]: e.target.value
      })
    } else {
      return setOtForm({
        ...otForm,
        [e.target.name]: e.target.value
      });
    }
  }

  return (
    <>
      <div className='ot_form_input_checkbox'>
        <label htmlFor='OTProductWatch'>
          <input
            checked={otForm.type === 'reloj' && true}
            id='OTProductWatch'
            name='type'
            onChange={ handleChange }
            type='radio'
            value='reloj'
          />
          Reloj
        </label>
        <label htmlFor='OTProductBijou'>
          <input
            checked={otForm.type === 'bijou' && true}
            id='OTProductBijou'
            name='type'
            onChange={ handleChange }
            type='radio'
            value='bijou'
          />
          Bijou
        </label>
      </div>
      <Inputs 
        handleChange={ handleChange }
        otForm={ otForm }
      />
    </>
  )
}
