
//Si un string que solo pueda tener letras, tiene un número -> hasNumber.test(string);
const hasNumber = /\d/;

const validator = ( otForm, setError, reject ) => {

  const {
    dni,name,last_name,telephone,mail,mail2, has_warranty, warranty,
    type, brand, model,  attender,
    ub1, up_belt_1, wc1, watch_center_1, db1, down_belt_1,
    ub2, up_belt_2, wc2, watch_center_2, db2, down_belt_2,
    place,
    /* plan, diagnostic, observations, repair_date, total_price, ot_slug, file*/
  } = otForm;

  if( !dni ) { // DNI vacío
    setError({ status:true, message:'El campo de -DNI- es obligatorio' })
    return (false)
  }

  if( dni && !Number(dni) ) { // DNI no es número
    setError({ status:true, message:'El campo de -DNI- sólo acepta números' })
    return (false)
  }
  
  if( !name || !last_name ) { // Nombre y Apellido vacíos
    setError({ status:true, message:'Los campos de -nombre y apellido- son obligatorios' })
    return (false)
  }


  if( name.length < 1 // Nombre con al menos una letra
    || last_name.length < 1 // Apellido con al menos una letra
    || hasNumber.test(name) // Nombre tiene un número
    || hasNumber.test(last_name) // Apellido tiene un número
  ) {
    setError({ 
      status:true,
      message:'Asegúrese que los campos de -nombre y apellido- tengan la información correcta'
    })
    return (false)
  }
  
  if( !telephone ) { // Teléfono vacío
    setError({ status:true, message:'El campo de -Teléfono- es obligatorio' })
    return (false)
  }

  if( telephone && !Number(telephone) ) { // Teléfono no es número
    setError({ status:true, message:'El campo de -Teléfono- sólo acepta números' })
    return (false)
  }

  //if(telephone.length < 10 || telephone.length > 15) { // Teléfono no tiene la longitud correcta
  //  setError({ 
  //    status:true,
  //    message:'Asegúrese que el campo de -Teléfono- tenga la información correcta' 
  //  })
  //  return (false)
  //}
  
  if( !mail ) { // Correo está vacío
    setError({ status:true, message:'El campo de -Correo- es obligatorio' })
    return (false)
  }

  if( !mail.includes('@') ) { // Correo no contiene arroba('@')
    setError({ 
      status:true,
      message:'Asegúrese que el campo de -Correo- tenga la información correcta'
    })
    return (false)
  }
  
  if( mail2 && !mail2.includes('@') ) { // Correo secundario, si existe, pero no contiene '@'
    setError({ 
      status:true,
      message:'Asegúrese que el campo de -Correo- secundario tenga la información correcta'
    })
    return (false)
  }
  
  if( has_warranty && !warranty ) { // Tiene garantía pero no es definida
    setError({ 
      status:true,
      message:'Si el cliente tiene una garantía, por favor ingrese la fecha correspondiente'
    })
    return (false)
  }
  
  if( !type ) { // Tipo de RMA vacío(?)
    setError({ status:true, message:'Elija entre -Reloj o Bijou-' })
    return (false)
  }

  if( !brand ) { // Marca vacía
    setError({ status:true, message:'El campo de -Marca- es obligatorio' })
    return (false)
  }

  if( !model ) { // Modelo vacío
    setError({ status:true, message:'El campo de -Modelo- es obligatorio' })
    return (false)
  }

  if( !attender ) { // Persona que atiende vacío
    setError({ status:true, message:'Es obligatorio saber quién ha -atendido- al cliente' })
    return (false)
  }


  if( (ub1 && !up_belt_1) || (ub2 && !up_belt_2) ) {
    setError({ 
      status:true,
      message:`Si hay alguna observación en el ${ub1 ? 'Reverso' : 'Frente'} de la -Correa Superior- por favor descríbala` 
    })
    return (false)
  }
  
  if( (db1 && !down_belt_1) || (db2 && !down_belt_2) ) {
    setError({ 
      status:true,
      message:`Si hay alguna observación en el ${db1 ? 'Reverso' : 'Frente'} de la -Correa Inferior- por favor descríbala` 
    })
    return (false)
  }

  if( (wc1 && !watch_center_1) || (wc2 && !watch_center_2) ) {
    setError({ 
      status:true,
      message:`Si hay alguna observación en el ${wc1 ? 'Reverso' : 'Frente'} de la -Parte Central- por favor descríbala` 
    })
    return (false)
  }

  //if(!repair_date) {
  //  setError({ status:true, message:'El campo de -Reparación Estimada- es obligatorio' })
  //  return (false)
  //}

  if( !place ) {
    setError({ status:true, message:'El campo de -Ubicación para Retiro- es obligatorio' })
    return (false)
  }

  //if(!total_price) {
  //  setError({ status:true, message:'El campo de -Total ARG$- es obligatorio' })
  //  return (false)) 
  //}
  
  return true
}

export default validator
