
export const updateVoucherState = {
    code:'',
    rma_id:'',
    client_id:'',
  }


export const errorState = {
	status:false,
	message:''
}

export const modalState = {
  modalShow: false,
}
