import React from 'react' 
import ReactToPdf from 'react-to-pdf'
const Barcode = require('react-barcode');

export const Viewer = () => {
          const ref = React.createRef();
  return (
    <div className='panel_cliente_marco_interno'>
        {/* info faltante en archivo Viewer.txt */}

      <div>
        <ReactToPdf targetRef={ref} filename="div-blue.pdf">
            {({toPdf}) => (
                <button onClick={toPdf}>Generate pdf</button>
            )}
        </ReactToPdf>
        <div className='pdf_a4_px_size' ref={ref}>
          <div className='header'>
            <figure className='header_logo'>
              <img src={process.env.PUBLIC_URL + '/stylestore_logo.png'} alt='company logo' />
            </figure>
            <div className='header_title'>
              <h3>Registro de Voucher <strong>OT #21312321</strong></h3>
            </div>
          </div>
          <div className='body'>
            <div className='body_ot'>
              <div className='subtitle'>
                <h4>Datos del Voucher OT</h4>
              </div>
              <div>
                  <p>Número OT: </p>
                  <span>#21312321</span>
                  {/* <span>{ rma.slug ? `#${rma.slug}` : `Ticket #${detail.slug ? detail.slug : ''}` }</span> */}
              </div>
              <div>
                  <p>Código Voucher: </p>
                  <span>3XAMPL30FC0D3</span>
                  {/* <span>{ detail.status?.name }</span> */}
              </div>
              <div>
                  <p>Fecha de inicio: </p>
                  <span>20-10-2021</span>
                  {/* <span>{ rma.created_at ? formatDateWithTime(rma.created_at) : '-'}</span> */}
              </div>
              <div>
                  <p>Fecha fin: </p>
                  <span>20-01-2022</span>
                  {/* <span>{ rma.created_at ? formatDateWithTime(rma.created_at) : '-'}</span> */}
              </div>
              <div>
                  <p>Estado: </p>
                  <span>Activo</span>
                  {/* <span>{ rmaObject?.brand.name }</span> */}
              </div>
              <div>
                  <p>Usado: </p>
                  <span>Sin Usar</span>
                  {/* <span>{ rmaObject?.model }</span> */}
              </div>
            </div>
          </div>
          <div className='footer'>
            <Barcode 
              value='3XAMPL30FC0D3'
              height='75'
            />
            <div className='footer_created_by'>
              <span>© Copyright 2022 C-Team - All Rights Reserved </span>
              <span>Jorge 197 Adrogue 1846 Bs As, Argentina</span>
              <span>https://soporte.cteamglobal.com/</span>
              <span>soporte@c-team.com.ar</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
