import React from "react";
import { Button, Modal } from "vendor/react-bootstrap";
import { CSVLink } from "vendor/react-csv";

export const DialogConfirmation = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <h4>Confirmación</h4>
        <p>
          Para confirmar que desea descargar el reporte correspondiente con la
          fecha suministrada, por favor, haga click en el botón "Solicitar
          Reporte"
        </p>
      </Modal.Body>
      <Modal.Footer>
        {/* @TODO: Agregar botón para cuando se pueda exportar en .xlsx */}
        {props.form.data && props.form.extension === "csv" && (
          <CSVLink className="btn btn-primary" data={props.form.data}>
            Solicitar Reporte
          </CSVLink>
        )}
        <Button variant="outline-primary" onClick={props.onHide}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
