import Axios from "axios";
import { TYPES } from "../../types/types";
import { isLoading, isNotLoading } from "../ui";
import { otTicketDetail } from "./ot-detail";


export const getClientOTTicketInfo = () => {
    return async(dispatch) => {
        dispatch( isLoading() );

        await Axios.get('api/rma/create', {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then(res => {
            dispatch(setOTTicketFormInfo(res.data));
            dispatch( isNotLoading() );
        })
        .catch(err => {
            alert('Falló la creación del ticket, intente nuevamente');
            dispatch( isNotLoading() );
        })
    }
}

export const createOrUpdateClientOTTicket = ( fd, setOtMessage, code, setReadyPdf ) => {
  return async(dispatch) => {
      dispatch( isLoading() );
        await Axios.post(`api/rma/${code}`, fd, {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then(res => {
            dispatch( isNotLoading() );
            dispatch( otTicketDetail(res.data.data) );

            setOtMessage({
                status: (code === 'edit') ? 'actualizado' : 'creado', 
                message: res.data.message,
                show: true
            })
            setReadyPdf(true)

            return
        })
        .catch(err => {
            // alert('Falló la creación del ticket, intente nuevamente');
            // console.log('err',err.response)
            setOtMessage({
                status: 'error', 
                message: 'Falló la creación del ticket, intente nuevamente',
                show: true
            })
            setReadyPdf(false)
            dispatch( isNotLoading() );
        })
  }
}

export const otSearchClient = (dni,setUserSearch) => {
    return async(dispatch) => {
        dispatch( isLoading() );

        await Axios.post('api/rma/find', dni, {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then(res => {
            const { success, client = '' } = res.data;
            if( success ) {
                dispatch(setClientInfo(client))
                setUserSearch({status:false, message:''})
                return dispatch( isNotLoading() );
            } 
            
            setUserSearch({status:true, message:'No se encontró el usuario'})
            return dispatch( isNotLoading() );
        })
        .catch(err => {
            alert('Falló la búsqueda del usuario, intente nuevamente');
            dispatch( isNotLoading() );
        })
    }
}

export const getOTEditInfo = (slug) => {
    return async(dispatch) => {
        dispatch( isLoading() );

        await Axios.get(`api/rma/${slug}/edit`, {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then(res => {
            dispatch(setOTTicketFormInfo(res.data));
            dispatch( isNotLoading() );
        })
        .catch(err => {
            alert('Falló la creación del ticket, intente nuevamente');
            dispatch( isNotLoading() );
        })
    }
}

const setOTTicketFormInfo = ticketInfo => ({type: TYPES.setOTTicketFormInfo, ticketInfo });
const setClientInfo = client => ({type: TYPES.setOTClientInfo, client });
export const deleteClientInfo = client => ({type: TYPES.deleteClientInfo});

const setVoucherOTInfoToCreate = info => ({
    type:TYPES.setVoucherOTInfoToCreate, info
})
