import React from 'react'

export const ErrorInOTForm = ({setError, error}) => {

  const { status, message } = error;

  return (
    <>
      {
        status
        && <p className='error_in_ot'>{ message }</p>
      }
    </>
  )
}
