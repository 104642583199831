import React, { useState } from "react";
import { useSelector } from "react-redux";

export const OTWatchmakerToRepairList = ({ setToRepair, toRepair }) => {
  const [toggle, setToggle] = useState({
    additionalCommentToggle: false,
  });

  const handleChange = (e) => {
    const aux = toRepair.observations.filter((item) => item !== e.target.value);
    setToRepair({
      ...toRepair,
      observations: e.target.checked
        ? [...toRepair.observations, e.target.value]
        : [...aux],
    });
  };
  const handleOther = (e) => {
    return setToRepair({
      ...toRepair,
      [e.target.name]: e.target.value,
    });
  };

  const handleToggle = (e) => {
    setToggle({ ...toggle, [e.target.name]: e.target.checked });
    if (!e.target.checked) {
      return setToRepair({ ...toRepair, [e.target.name]: "" });
    }
  };

  const { observations } = useSelector((state) => state.ot.watchmaker_info);
  return (
    <form className="ot_watchmaker_to_repair_list">
      {observations
        .filter((item) => item.name !== "Otros")
        .map((item) => (
          <div key={item.id}>
            <input
              id={item.name + "-" + item._id}
              type="checkbox"
              name="observations"
              value={item.id}
              onChange={handleChange}
            />
            <label htmlFor={item.name + "-" + item._id}>{item.name}</label>
          </div>
        ))}
      <div>
        <input
          id="other_repair_list_item"
          type="checkbox"
          name="additionalCommentToggle"
          onChange={handleToggle}
        />
        <label htmlFor="other_repair_list_item">Otro:</label>
        <input
          type="text"
          disabled={!toggle.additionalCommentToggle}
          onChange={handleOther}
          name="additionalComment"
          value={toRepair.additionalComment}
        />
      </div>
      {toRepair.repairType === "prep" && (
        <div className="prep_budget_input_container">
          <label htmlFor="other_repair_list_item_budget">Presupuesto:</label>
          <input
            type="text"
            name="budget_amount"
            onChange={handleOther}
            value={toRepair.budget_amount}
          />
        </div>
      )}
    </form>
  );
};

/* 
const toRepairListObject = [
  {
    _id:1,
    name:'Cambio de pila común'
  },
  {
    _id:2,
    name:'Cambio de pila sumergible'
  },
  {
    _id:3,
    name:'Cambio de máquina completa'
  },
  {
    _id:4,
    name:'Reparación tije y corona'
  },
  {
    _id:5,
    name:'Reparación general de la maquinaria'
  },
  {
    _id:6,
    name:'Cambio de cristal mineral'
  },
  {
    _id:7,
    name:'Cambio de acrílico'
  },
  {
    _id:8,
    name:'Reparación de correa'
  },
  {
    _id:9,
    name:'Limpieza de contactos'
  },
  {
    _id:10,
    name:'Cambio de caja'
  },
  {
    _id:11,
    name:'Cambio de tapa'
  },
  {
    _id:12,
    name:'Cambio de junta de tapa'
  },
  {
    _id:13,
    name:'Cambio de junta de corona'
  },
  {
    _id:14,
    name:'Cambio de correa'
  },
  {
    _id:15,
    name:'Reparación bijoux'
  },
  {
    _id:16,
    name:'Cambio de bisel'
  },
  {
    _id:17,
    name:'Pulido de bisel'
  },
  {
    _id:18,
    name:'Cambio de cierre'
  },
  {
    _id:19,
    name:'Colocación de aguja'
  },
  {
    _id:20,
    name:'Colocación de detalles sueltos'
  },
  {
    _id:21,
    name:'Ajuste de aguja'
  },
  {
    _id:22,
    name:'Cambio de cuadrante'
  },
] */
