import React from "react";
import { Tooltip } from "vendor/tooltip";

export const TabIcon = ({ content, iconClass }) => {
  return (
    <Tooltip content={content} place="top">
      <div className="tab-icon">
        <i className={iconClass} />
      </div>
    </Tooltip>
  );
};
