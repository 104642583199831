import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { loadOtModalComments } from '../../../actions/ot/ot-comments'
import { loadRma, updateRma } from '../../../actions/ot/ot-detail'
import { otGetInfo } from '../../../actions/ot/ot-get'
import { formatDateWithTime } from '../../../helpers/helpers'

import { isCS, isPDV } from '../../../helpers/permission'

export const OTUpdates = (props) => {
  const {role : {name:role}, user} = useSelector(state => state.auth)
  const {comments} = useSelector(state => state.ot)
  const [error, setError] = useState({status:false, message:''})
  const dispatch = useDispatch()
  const slug = props.show.info?.slug

  useEffect(() => {
    if(slug) dispatch(loadOtModalComments(slug))
  }, [slug,dispatch])
  return (
    <Modal
      show={props.show && props.show.status}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='ot_watchmaker_repair_modal'
    >
      <Modal.Header closeButton className='ot_watchmaker_repair_modal_top'>
        <Modal.Title id="contained-modal-title-vcenter watchmaker_to_derive_title">
          <h6>Actualizaciones OT</h6>
          <span>OT #<strong>{slug}</strong></span>
        </Modal.Title>
      </Modal.Header>
      {comments && comments.length > 0 &&
        <Modal.Body className='ot_watchmaker_repair_modal_body m-0'>
          <ul className="ot_comments_list">
            {(comments && comments.length > 0) && comments.map(comment => 
              <li 
                className={`ot_comment_block ${user.username === comment.user?.username && 'current_user'}`}
                key={comment.id}
              >
                <span className="ot_comment_detail">
                  {comment.comment}
                </span>
                <small className="ot_comment_info">
                  {comment.user?.username} - {formatDateWithTime(comment.created_at)}
                </small>
              </li>
              )}
          </ul>
        </Modal.Body>
      }
      <Modal.Footer className='ot_watchmaker_repair_modal_bottom ot_watchmaker_derive_buttons'>
        {/* <Button
          variant="info" 
        >
          <i className="fas fa-clipboard-check"></i>Enviar ahora
        </Button>

        <Button 
          variant="danger" 
          onClick={props.onHide}
        >
          Volver
        </Button> */}

      </Modal.Footer>
    </Modal>
  )
}
