import makeRequest from "../../../actions/makeRequest"
import { otGetVoucherInfo } from "../../../actions/ot/ot-voucher"
import { postRequest } from "./requests"
import { voucherState } from "./states"

export const handleChange = (stateFn, e) => {
    const {name,value,checked} = e.target
    if(name === 'active' || name === 'is_external') {
      return stateFn(v => ({
        ...v, 
        [name]:checked
      }))
    }
    return stateFn(v => ({
      ...v,
      [name]:value
    }))
  }

export const submitValidation = (voucher) => {

  const {
    description,
    rma_id,
    client_id,
  } = voucher

  if( !description ) return {status:true, message:'El voucher OT debe tener una descripción'}
  if( !rma_id ) return {status:true, message:'Es necesario que el voucher posea un OT'}
  if( !client_id ) return {status:true, message:'Es necesario que el voucher posea el DNI del cliente'}

  return false
}

export const submitFunction = ({ voucher, setVoucher, dispatch }) => {
  
	const auxRequest = {...postRequest(voucher.voucher_id), data:voucher}
  /* postRequest.data = {
    ...voucher, 
    rma_id : auxSlug,
    description: description.length ? description : `Descripción por defecto de voucher para OT ${auxSlug}`,
    date_from:justDate(date_from,true),
    date_to:justDate(date_to,true),
  } */


  return new Promise(async(resolve) => {
    const aux = await dispatch(makeRequest(auxRequest))
    setVoucher(voucherState)
		dispatch(otGetVoucherInfo());
    resolve(aux)
  })
}
