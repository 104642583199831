import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

const ConfirmationModal = ({ 
	onHide, fnToInvoke, modalSize = 'lg', modalText, ...rest 
}) => {
	const [message, setMessage] = useState({active:true, body:''})
	const onSuccess = async(e) => {
		e.preventDefault()
    const {message, response} = await fnToInvoke()
		return setMessage({active:false, body: message, response})
	}
	return (
    <Modal
      {...rest}
      size={modalSize}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{border:'none'}}>
        <Modal.Title id="contained-modal-title-vcenter">
          Mensaje de confirmación
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          { message.body?.length ? message.body : modalText }
        </p>
        <span>{message.response}</span>
      </Modal.Body>
      <Modal.Footer style={{border:'none'}}>
        <Button onClick={(e) => {
					e.preventDefault()
					setMessage({active:true, body:''})
					onHide()
				}}>Close</Button>
				{
					typeof fnToInvoke === 'function'
					&& message.active
					&& <Button variant='success' onClick={onSuccess}>Enviar</Button>
				}
      </Modal.Footer>
    </Modal>
	)
}

export default ConfirmationModal
