import React, { useState } from "react";

import { Tabs, Tab } from "vendor/react-bootstrap";
import { Datepicker } from "vendor/forms";

import { DialogConfirmation, TabIcon } from "./components";
import { useExportarReportes } from "../../hooks";

export const LayoutForms = () => {
  const {
    key,
    setKey,
    modalShow,
    postExportInfo,
    closeConfirmationModal,
    onChange,
    form,
    extension,
    startDate,
    endDate,
  } = useExportarReportes(useState);

  return (
    <>
      <Tabs className="layout-forms" activeKey={key} onSelect={setKey}>
        {/* @TODO: Revisar código y figurar si se puede hacer un refactor para modularizar algunas partes */}
        <Tab
          eventKey={"try"}
          title={
            <TabIcon content={"esto es el tooltip"} iconClass={"fas fa-cog"} />
          }
        >
          <form className="layout-forms-export-ot">
            <h4 className="layout-forms-export-ot-title">Exportar OT</h4>
            <fieldset className="layout-forms-export-ot-input-wrapper">
              <legend className="layout-forms-export-ot-legend">
                Seleccione un rango de fecha para poder exportar la información
                del OT en un reporte con el formato seleccionado
              </legend>
              <div className="layout-forms-export-ot-input-container">
                <label>Rango de fecha para el reporte</label>
                <Datepicker
                  name="range"
                  startDate={startDate}
                  endDate={endDate}
                  onChange={onChange}
                  placeholderText="Seleccionar rango de fecha"
                  selectsRange
                  isClearable
                  maxDate={new Date()}
                  showDisabledMonthNavigation
                />
              </div>
              <div className="layout-forms-export-ot-input-container">
                <label>Tipo de extensión para el reporte</label>
                <select
                  value={extension}
                  onChange={onChange}
                  name="extension"
                  disabled
                >
                  <option value="csv">CSV</option>
                </select>
              </div>
            </fieldset>

            <fieldset className="layout-forms-export-ot-action-wrapper">
              <button onClick={postExportInfo}>
                <i className="fas fa-file-export" />
                Descargar reporte
              </button>
            </fieldset>
          </form>
        </Tab>
      </Tabs>
      <DialogConfirmation
        show={modalShow}
        onHide={closeConfirmationModal}
        form={form}
      />
    </>
  );
};
