
export const postRequest = (id) => ({ 
  method:'PUT', 
  url:`api/voucher/${id}`, 
  data: {}, 
  storeType:'', 
  headers:{
    'Authorization':'Bearer ' + localStorage.getItem('token')
  } 
})

