import React from "react";
import TT from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const Tooltip = ({ children, place, ...props }) => {
  return (
    <OverlayTrigger
      placement={`${place || "right"}`}
      overlay={
        <TT className={`tooltip ${props.classes || ""}`} {...props}>
          {props.content || "tooltip"}
        </TT>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

export { Tooltip };
