import { configNames } from "../constants/config-names";

/**
 * @param {Object} config Objecto que será validado y filtrado para comprobar que
 * 	tiene las configuraciones correctas según axios.
 * @returns {Object} Devuelve nueva configuración filtrada con los pares key/value disponibles
 * @example 
		const auxConfig = {
			data:'test',
			someRandom:'thing',
			decompress:''
		}

		console.log(configNamesValidator(auxConfig))
		// Object { data: "test", decompress: "" }
 */
export const configNamesValidator = (config) => {
  const newConfig = {};

  const currentConfigArray = Object.entries(config);

  if (currentConfigArray.length) {
    currentConfigArray
      .filter((configName) => configNames.includes(configName[0]))
      .forEach((conf) => (newConfig[conf[0]] = conf[1]));

    return newConfig;
  }

  console.warn("La configuración está vacía");
  return config;
};
