import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { OTCreateTicket } from "../../components/OT/OTCreateTicket/OTCreateTicket";
import { OTEdit } from "../../components/OT/OTCreateTicket/OTEdit";
import { Viewer } from "../../components/OT/OTCreateTicket/OTExport/Viewer";
import { OTDetail } from "../../components/OT/OTDetails/OTDetail";
import { Perfil } from "../../components/Perfil";
import { isCS, isPDVorCS, isWatchmaker, isPDV } from "../../helpers/permission";
import { PATHS } from "../../types/paths";

import { Reportes } from "modules/reportes/views";

import { OtTicketsIndex, TicketsDetail, ClientTicketIndex } from "../../pages";

const Routes = () => {
  const { name: role } = useSelector((state) => state.auth.role);
  return (
    <div className="panel-general-container">
      <div className="panel-general-inner">
        <Switch>
          <Route //RUTA PARA CONFIGURACIONES DE PERFIL
            exact
            path={PATHS.panelConfigProfile}
            component={Perfil}
          />

          {role && isPDVorCS(role) && (
            <Route //RUTA PARA CREAR OT
              exact
              path={PATHS.otCreateTickets}
              component={OTCreateTicket}
            />
          )}

          {(isCS(role) || isPDV(role)) && (
            <Route //RUTA PARA EDITAR OT
              exact
              path={PATHS.otEdit}
              component={OTEdit}
            />
          )}

          <Route //RUTA PARA OT INDEX
            exact
            path={PATHS.otTickets}
            component={OtTicketsIndex}
          />

          <Route //RUTA PARA DETALLES DE OT
            path={PATHS.otDetails}
            component={OTDetail}
          />

          {isCS(role) && (
            <Route //RUTA PARA DETALLES DE OT
              path={PATHS.reportes}
              component={Reportes}
            />
          )}

          {/* <Route //RUTA PARA DETALLES DE OT
						path={PATHS.editRoom} 
						component={Viewer} 
					/> */}

          <Redirect to={PATHS.otGeneralTickets} />
        </Switch>
      </div>
    </div>
  );
};

export default Routes;
