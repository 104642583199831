import { apiInstance } from "vendor/axios";

import { isNotLoading } from "actions/ui";
import { justDate } from "helpers/helpers";

import { setInStore } from "../store/actions";
import { TYPES } from "../store/types";

const storeType = TYPES.reportes;
const CONFIG = {
  url: "api/reportes",
  method: "GET",
};

const setConfig = (range = []) => {
  if (range?.length) {
    localStorage.setItem("reportes-submit", true);
    return { ...CONFIG, params: { range } };
  }
  return CONFIG;
};

export const useFiltroReportes = (setDateRange, dispatch) => {
  const requestConfig = apiInstance();

  const setRange = (range) => {
    // dispatch(setInStore(TYPES.reportesRango, range));
    localStorage.setItem("reportes-rango", JSON.stringify(range));
  };

  const setRangeInStore = (dates) => {
    setDateRange(dates);
    if (!dates[0] && !dates[1]) {
      // dispatch(setRange([]));
      localStorage.setItem("reportes-submit", false);
      setRange([]);
      return;
    }
    if (dates[0] && dates[1]) {
      setRange(dates.map((date) => justDate(date, true)));
      // dispatch(setRange(dates.map((date) => justDate(date, true))));
      // localStorage.setItem("reportes-submit", true);
      return;
    }
    return;
  };

  const getReportes = () => {
    const submitNow = JSON.parse(localStorage.getItem("reportes-submit"));
    const range = JSON.parse(localStorage.getItem("reportes-rango")) || [];
    if (range?.length || submitNow)
      return requestConfig
        .request(setConfig(range))
        .then(({ data: { data } }) => {
          dispatch(setInStore(storeType, data));
          dispatch(isNotLoading());
        })
        .catch(() => console.log("fail"));
  };

  const submit = () => {
    getReportes();
  };

  const restart = () => {
    localStorage.setItem("reportes-rango", JSON.stringify([]));
    localStorage.setItem("reportes-submit", true);
    setDateRange([null, null]);
    getReportes();
    localStorage.setItem("reportes-submit", false);
  };

  return { setRangeInStore, submit, restart };
};
