import { combineReducers } from "redux";

//Reducers
import { authReducer } from "../reducers/authReducer";
import { detailReducer } from "../reducers/detailReducer";
import { createReducer } from "../reducers/createReducer";
import { headerReducer } from "../reducers/headerReducer";
import { contentReducer } from "../reducers/contentReducer";
import { uiReducer } from "../reducers/uiReducer";
import { userReducer } from "../reducers/userReducer";
import { clientReducer } from "../reducers/clientReducer";
import { otReducer } from "../reducers/otReducer";
import { lastDispatchReducer } from "../reducers/lastDispatchReducer";
import { reportesReducer } from "modules/reportes/store";

const reducers = combineReducers({
  auth: authReducer,
  user: userReducer,
  ui: uiReducer,
  storage: contentReducer, //tickets y tasks
  header: headerReducer,
  detail: detailReducer,
  create: createReducer,
  client: clientReducer,
  ot: otReducer,
  lastDispatch: lastDispatchReducer,
  reportes: reportesReducer,
});

export default reducers;
