import Axios from "axios"
import { PATHS } from "../../types/paths";
import {TYPES} from '../../types/types';

export const loadRma = slug => {
  return async(dispatch) => {
        await Axios.get(`api/rma/${slug}/detail`, {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then(res => {
          dispatch(otTicketDetail(res.data.data));
        })
        .catch(err => {
          alert('Hubo un problema con la carga de la OT')
          return Promise.reject(PATHS.otGeneralTickets)
          //console.log(err)
            //alert('Falló la búsqueda de información sobre OT, intente nuevamente: ', err);
        })
  }
}

export const updateRma = (slug,fd) => {
  return async() => {
    await Axios.post(`api/rma/${slug}/update`, fd,{
      headers:{
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
    .then(res => {
      return alert(res.data.message)
    })
    .catch(err => {
      return alert(err.response)
    })
  }
}

export const otTicketDetail = ticket => ({
    type: TYPES.otTicketDetail,
    payload: ticket
})