import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { loadRma, updateRma } from '../../../actions/ot/ot-detail'
import { otGetInfo, otGetToAssignInfo, otGetWatchmakersListToDerive } from '../../../actions/ot/ot-get'
import { otSendOtListToUpdate, otSendWatchmakerUpdate } from '../../../actions/ot/ot-update'
import { isWatchmaker, isCS } from '../../../helpers/permission'

export const OTWatchmakerDerive = (props) => {
  const {name:role} = useSelector(state => state.auth.role)
  const {username} = useSelector(state => state.auth.user)
  const {update_info} = useSelector(state => state.ot)
  const [error, setError] = useState({status:false, message:''})
  const [toDerive, setToDerive] = useState({watchmaker:''})
  const dispatch = useDispatch()
  const {pathname} = useLocation()

  const handleDeriveChange = (e) => {
    setToDerive({watchmaker:e.target.value})
  }
  const handleDerive = async(e) => {
    e.preventDefault()

    let wm
    if (isCS(role) || isWatchmaker(role)) {
      wm = update_info.filter(watch => watch.name === toDerive.watchmaker)
    }
    if(!wm[0]) {
      return setError({
        status:true,
        message:'Es necesario seleccionar a un relojero'
      })
    }
    if(wm[0].username === username) {
      return setError({
        status:true,
        message:'Es necesario seleccionar un relojero diferente'
      })
    }
    setError({status:false, message:''})

    const fd = new FormData()

    fd.append('watchmaker',wm[0].id)

    if(isWatchmaker(role)) {

      await dispatch(otSendWatchmakerUpdate(fd,props.slug,'watchmakers-derive'))
      return props.onHide()

    } else if(isCS(role) && 
      (props.status.name === 'Presupuesto aceptado'
      || props.status.name === 'En Customer Service'
      || props.status.name === 'Reparacion gratuita')) {

        fd.append('ot_list',props.slug)
        dispatch( otSendOtListToUpdate(fd,'ot-to-technical'))
        dispatch(otGetInfo('general'));
        props.onHide()

    } else if (isCS(role) && props.status.name === 'Reparado') {

      fd.append('action','reject')
      dispatch(updateRma(props.slug,fd))
      dispatch(otGetInfo('general'));
      if(pathname.includes('panel/ot/repaired')) {
        await dispatch(otGetInfo('repaired'));
        return props.onHide()
      }
      await dispatch(loadRma(props.slug))
      return props.onHide()
    }
  }
  return (
    
    <Modal
      show={props.show && props.show.status}
      onHide={props.onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='ot_watchmaker_repair_modal'
    >
      <Modal.Header closeButton className='ot_watchmaker_repair_modal_top'>
        <Modal.Title id="contained-modal-title-vcenter watchmaker_to_derive_title">
          <h6>A relojeros</h6>
          <span>OT #<strong>{props.slug}</strong></span>
          
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='ot_watchmaker_repair_modal_body m-0'>
        <div className='ot_watchmaker_input_to_derive'>
          <label htmlFor="watchmaker_to_derive">
            {
              error.status 
              ? <small className='text-danger'>{error.message}</small>
              : <small>Lista de relojeros</small>
            }
          </label>
          <input 
            id='watchmaker_to_derive'
            list='watchmaker_to_derive_datalist'
            onChange={handleDeriveChange}
          />
          <datalist
            id='watchmaker_to_derive_datalist'
          >
            <option value='--relojero--'>--relojero--</option>
            { ((isCS(role) || isWatchmaker(role)) &&
              update_info) && update_info
              .filter(user => user.username !== username)
              .map(wm => (
                <option 
                  key={wm.id}
                  value={wm.name}
                >
                  {wm.name}
                </option>
              ))
            }
          </datalist>
        </div>
      </Modal.Body>
      <Modal.Footer className='ot_watchmaker_repair_modal_bottom ot_watchmaker_derive_buttons'>
        <Button
          variant="info"
          type='button'
          onClick={handleDerive}
        >
          {
            isWatchmaker(role) &&
            <>
              <i className="fas fa-clipboard-check"></i>Derivar
            </>
          }
          {
            isCS(role) &&
            <>
              <i className="fas fa-clipboard-check"></i>Enviar ahora
            </>
          }
        </Button>

        <Button 
          variant="danger" 
          onClick={props.onHide}
        >
          Volver
        </Button>

      </Modal.Footer>
    </Modal>
  
  )
}
