const formDataSetter = (otForm) => {
  const list = Object.entries(otForm).filter(
    (entry) =>
      entry[0] === "dni" ||
      entry[0] === "name" ||
      entry[0] === "last_name" ||
      entry[0] === "telephone" ||
      entry[0] === "mail" ||
      entry[0] === "type" ||
      entry[0] === "brand" ||
      entry[0] === "model" ||
      entry[0] === "attender" ||
      entry[0] === "place" ||
      entry[0] === "repair_date"
  );

  const {
    mail2,
    warranty,
    is_saler,
    plan,
    diagnostic,
    observations,
    ub1,
    up_belt_1,
    wc1,
    watch_center_1,
    db1,
    down_belt_1,
    ub2,
    up_belt_2,
    wc2,
    watch_center_2,
    db2,
    down_belt_2,
    total_price,
    ot_slug,
    file,
    /* dni,name,last_name,telephone,mail,has_warranty, type, brand, model, attender, repair_date, place, */
  } = otForm;

  const fd = new FormData();

  for (let i = 0; i < list.length; i++) {
    fd.append(`${list[i][0]}`, list[i][1]);
  }

  fd.append("is_saler", is_saler ? 1 : 0);

  if (warranty) {
    fd.append("has_warranty", 1);
    fd.append("warranty", warranty);
  } else {
    fd.append("has_warranty", 0);
  }

  if (ot_slug) {
    fd.append("ot_slug", ot_slug);
  }

  if (total_price) {
    fd.append("total_price", total_price);
  }

  if (mail2) {
    fd.append("mail2", mail2);
  }

  if (plan) {
    fd.append("plan", plan);
  }

  if (diagnostic) {
    fd.append("diagnostic", diagnostic);
  }

  if (observations) {
    fd.append("observations", observations);
  }

  if (ub1 && up_belt_1) {
    fd.append("up_belt_1", up_belt_1);
  }

  if (ub2 && up_belt_2) {
    fd.append("up_belt_2", up_belt_2);
  }

  if (db1 && down_belt_1) {
    fd.append("down_belt_1", down_belt_1);
  }

  if (db2 && down_belt_2) {
    fd.append("down_belt_2", down_belt_2);
  }

  if (wc1 && watch_center_1) {
    fd.append("watch_center_1", watch_center_1);
  }

  if (wc2 && watch_center_2) {
    fd.append("watch_center_2", watch_center_2);
  }

  if (file) {
    fd.append("ticketFile", file);
  }

  return fd;
};

export default formDataSetter;
