import { TYPES } from "../types/types";

const initialState = {
    detail: {},
    comments: {},
    //interactions: [],
    update_info:[],
    rma:{},
    rmaCollection:[],
    vouchers:[],
    observations:{},  
    deliver:[],  
    repairs:{},
    myTicketsToSend:{},
    watchmaker_info:{
        observations:[], 
        ticket:{},
        watchmakers:[]
    },
}

export const otReducer = (state = initialState, action) => {
    switch (action.type) {

        /** TICKETS TICKETS TICKETS TICKETS */
        case TYPES.otTicketDetail:
            return {
                ...state,
                detail: {...action.payload.ticket},
                comments: {...action.payload.comments},
                rma: {...action.payload.rma},
                rmaObject: {...action.payload.rma.swatch_object},
                observations: {...action.payload.observations},
                repairs: {...action.payload.repairs},
                myTicketsToSend: {...action.payload.myTicketsToSend},
                deliver: [...action.payload.deliver],
            };
        
        case TYPES.otRoleToDistribute:
            return{...state, update_info:[...action.payload]}
        
        case TYPES.otWatchmakerObservationsList:
            return{
                ...state, 
                watchmaker_info:{
                    ...state.watchmaker_info,
                    observations:[...action.payload.observations],
                    ticket:{...action.payload.ticket}
                }
            }
        case TYPES.otWatchmakerList:
            return{
                ...state, 
                watchmaker_info:{
                    ...state.watchmaker_info,
                    watchmakers:[...action.payload.watchmakers],
                    ticket:{...action.payload.ticket}
                }
            }

        case TYPES.otModalComments:
            return {
                ...state,
                comments: [...action.payload],
            };
        case TYPES.deleteOtModalComments:
            return {
                ...state,
                comments: [],
            };

        case TYPES.setVoucherOTInfoToCreate:
            return {
                ...state,
                ...action.payload
            }
        case TYPES.setVoucherIndex:
            return {
                ...state,
                vouchers: action.payload
            }

        case TYPES.deleteDetail:
            return {...initialState};

        default:
            return state;
    }
}
