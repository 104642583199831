import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router'
/* import { createClientOTTicket } from '../../../../../actions/ot/ot-create'; */
import { PATHS } from '../../../../../types/paths';
import { OTConfirmationModal } from './OTConfirmationModal';
import handleSubmit from './otCreateHandleSubmit';
import { OTDeletionModal } from './OTDeletionModal';

export const OTCreateButtonsGroup = ({initialState, setOtForm, otForm, setError, error}) => {
  
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  
  const [modalShow, setModalShow] = useState({status:false,info:null});
  const [erase, setErase] = useState({status: false, info:null});

  const handleSend = async(e) => {
    e.preventDefault();
    const validation = await handleSubmit(otForm, setError);
    if(validation) {
      setModalShow({status:true,info:validation})
    }

    //console.log(validation)
    /* return dispatch(createClientOTTicket(validation)) */
  }
  const handleErase = async() => {
    //e.preventDefault();
    return setErase({status:true, info:{setOtForm,initialState}})
  }
  
  return (
    <div className='ot_create_buttons_group'>

        <button //CREAR OT
          className='ot_create_final_ot_button'
          onClick={ handleSend }
        > 

        {location.pathname.includes('edit') ?
          <>
            <i className="fas fa-edit"></i>
            <small>Actualizar OT</small>
          </>:
          <>
            <i className="fas fa-plus-circle"></i>
            <small>Crear OT</small>
          </>
        }
        </button>

        <div className='ot_go_back_n_reset'>

          <button //VOLVER
            className='ot_go_back_button'
            onClick={() => history.push(PATHS.otGeneralTickets)}
          > 
            <i className="fas fa-undo-alt"></i>
            <small>Volver</small>
          </button>
          {/* 
          <button //RESET FORM
            className='ot_reset_button'
            onClick={handleErase}
          > 
            <i className="fas fa-eraser"></i>
            <small>Borrar</small>
          </button> */}

        </div>
        <OTConfirmationModal
          show={modalShow}
          onHide={() => setModalShow({status:false,info:null})}
        />
        <OTDeletionModal
          show={erase}
          onHide={() => setErase({status:false,info:null})}
        />
    </div>
  )
}
