import React from 'react'
import { formatDateWithTime } from '../../../../helpers/helpers';
const Barcode = require('react-barcode');

export const OTPDFReady = ({ot, auxRef}) => {
    const {detail, rma, rmaObject, deliver} = ot;  
  return (
    <div className='ot_pdf_viewer'>
          <div className='pdf_a4_px_size' ref={auxRef}>
              <div className='header'>
                <figure className='header_logo'>
                  <img src={process.env.PUBLIC_URL + '/stylestore_logo.png'} alt='company logo' />
                </figure>
                <div className='header_title'>
                  <h3>Registro de nuevo OT </h3>
                  <h6>para StyleStore</h6>
                </div>
              </div>
              <div className='body'>
                <div className='body_client'>
                  <div className='subtitle'>
                  <h4>Datos del cliente</h4>
                  </div>
                  <div>
                      <p>Nombre: </p>
                      <span>{rma.client && rma.client.name} { rma.client && rma.client.lastname } </span>
                  </div>
                  <div>
                      <p>DNI: </p>
                      <span>{ rma.client && rma.client.dni }</span>
                  </div>
                  <div>
                      <p>Teléfono: </p>
                      <span>{ rma.client && rma.client.phone }</span>
                  </div>
                  <div>
                      <p>Correo: </p>
                      <span>{ rma.client && rma.client.email }</span>
                  </div>
                  <div>
                      <p>Correo secundario: </p>
                      <span>{ (rma.client && rma.client.optional_email) ? rma.client.optional_email : '-' }</span>
                  </div>

                </div>
                <div className='body_ot'>
                  <div className='subtitle'>
                    <h4>Datos de la OT</h4>
                  </div>
                  <div>
                      <p>Número: </p>
                      <span>{ rma.slug ? `#${rma.slug}` : `Ticket #${detail.slug ? detail.slug : ''}` }</span>
                  </div>
                  <div>
                      <p>Estado: </p>
                      <span>{ detail.status?.name }</span>
                  </div>
                  <div>
                      <p>Fecha de registro: </p>
                      <span>{ rma.created_at ? formatDateWithTime(rma.created_at) : '-'}</span>
                  </div>
                  <div>
                      <p>Marca: </p>
                      <span>{ rmaObject?.brand.name }</span>
                  </div>
                  <div>
                      <p>Modelo: </p>
                      <span>{ rmaObject?.model }</span>
                  </div>
                  <div>
                      <p>Tipo: </p>
                      <span>{ rmaObject?.brand.type  }</span>
                  </div>
                  <div>
                      <p>Garantía: </p>
                      <span>{ rma.due_date ? formatDateWithTime(rma.due_date) : 'Sin garantía' }</span>
                  </div>
                  <div>
                      <p>Presupuesto: </p>
                      <span>{rma.total_amount ? `$${rma.total_amount} ` : 'Sin presupuesto'}</span>
                  </div>
                  <div>
                      <p>Fecha estimada: </p>
                      <span>{ rma.fix_date ? formatDateWithTime(rma.fix_date) : '-'}</span>
                  </div>
                  <div>
                      <p>Lugar de retiro: </p>
                      <span>{ deliver[0]?.username }</span>
                  </div>
                  <div>
                      <p>Plan </p>
                      <span>{rma.warranty_state?.name}</span>
                  </div>
                  <div className='bigtext'>
                      <p>Prediagnóstico: </p>
                      <span>{`${rma.pre_diagnostic?.toLowerCase()} ${rma?.other_pre_diagnostic ? rma.other_pre_diagnostic.toLowerCase() : ''}` }</span>
                  </div>
                  {
                      Boolean(rma.observation) &&
                      <div className='bigtext'>
                          <p>Observaciones: </p>
                          <span>{ rma.observation }</span>
                          {rma?.observations.length > 0 &&
                            <div className='bigtext_observations'>
                              { rma.observations.map(obs => (
                                <span
                                  key={obs.id}
                                >
                                  <strong>{obs.type} : </strong>{obs.observation}
                                </span>
                              )) }
                            </div>
                          }
                          
                      </div>
                  }
                </div>
              </div>
              <div className='footer'>
                <Barcode 
                  value={ `${rma?.slug}` }
                  height={75}
                  width={3}
                />
                <div className='footer_created_by'>
                  <span>© Copyright 2022 C-Team - All Rights Reserved </span>
                  <span>Jorge 197 Adrogue 1846 Bs As, Argentina</span>
                  <a href='https://soporte.cteamglobal.com/'>https://soporte.cteamglobal.com/</a>
                  <span>soporte@c-team.com.ar</span>
                </div>
              </div>
            </div>
        </div> 
        
  )
}
