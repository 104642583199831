import { setInStore } from "../store/actions";
import { TYPES } from "../store/types";

const storeType = TYPES.reportesBorrar;
export const useDeleteReportes = () => {
  const reportesBorrar = () => (dispatch) => dispatch(setInStore(storeType));
  return {
    reportesBorrar,
  };
};
