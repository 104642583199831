import React, { /* useEffect, */ useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

// import makeRequest from '../../../../actions/makeRequest';
// import { getRequest } from './requests';
import ConfirmationModal from '../../../../components_new/modals/SimpleConfirmationModal';
import { generateID, handleChange, submitFunction, submitValidation } from './events';
import { voucherState, errorState, modalState } from './states';


const OTCreateVoucherForm = () => {
  const dispatch = useDispatch(); 
  const {rmaCollection} = useSelector(state => state.ot)

  const [voucher, setVoucher] = useState(voucherState);
  const [error, setError] = useState(errorState);
  const [modal, setModal] = useState(modalState);

  const {
    code,
    date_from,
    date_to,
    description,
    rma_id,
    active
  } = voucher

	const submitSetup = { voucher, rmaCollection, setVoucher, setError, dispatch }

  const aux = {
      show: modal.modalShow,
      onHide:() => setModal({
        ...modal,
        modalShow: false,
      }),
      fnToInvoke: () => submitFunction(submitSetup),
      modalSize : 'md',
      backdrop : 'static',
			modalText: 'Para confirmar el envío de información para crear un nuevo Voucher OT, por favor presione en -Enviar-'
  }

	const submit = e => {
		e.preventDefault()
		const {status, message} = submitValidation({voucher,rmaCollection})
		if(status) {
			return setError({status,message})
		}
    
		setModal({
			...modal,
			modalShow: true,
		})
	}
  /* useEffect(() => {
    dispatch(makeRequest(getRequest))
  }, [dispatch]) */
  return (
      <form className='ot_search_form'>

          <div>
              <p className="panel_cliente_crear_ticket_nuevo">Crear Voucher OT</p>
              <small className={ 'panel_cliente_crear_ticket_aclaracion ' + (error.status && 'error_color')}>
                  {
                      error.status ? error.message : 'Ingrese los datos correspondientes para crear un nuevo voucher OT'
                  }
              </small>
              <div className="panel_cliente_crear_ticket_inputs">
                
                <div className="voucher_input_container ot_watchmaker_input_to_derive">
                  <label htmlFor='voucher_input_1'><small>Código del voucher</small></label>
                  <input 
                    type='text'
                    placeholder='3XAMPL30FC0D3'
                    name='code'
                    value={code}
                    id='voucher_input_1'
                    onChange={e => handleChange(setVoucher,e)}
                  />
                  <button 
                    className="generate-id"
                    onClick={e => generateID(setVoucher, e)}
                  >
                    <i className="fas fa-plus-circle"></i>
                  </button>
                </div>
                <div className="voucher_input_container ot_watchmaker_input_to_derive dates">
                  <small>Rango de fechas</small>
                  <input 
                    type='date'
                    name='date_from'
                    id='voucher_input_2'
                    value={date_from}
                    onChange={e => handleChange(setVoucher,e)}
                  />

                  <input 
                    type='date'
                    name='date_to'
                    id='voucher_input_3'
                    value={date_to}
                    onChange={e => handleChange(setVoucher,e)}
                  />
                </div>

                
                <small>OT para asignar el voucher</small>
                <input 
                  type='text'
                  placeholder='# OT'
                  name='rma_id'
                  id='voucher_input_6'
                  value={rma_id}
                  onChange={e => handleChange(setVoucher,e)}
                  // list='datalist_voucher_ot'
                />{/* 
                <datalist
                  id="datalist_voucher_ot"
                >
                  {rmaCollection?.map(rma => (
                    <option 
                      value={`OT #${rma.slug}`}
                      key={rma.slug}
                    >OT #{rma.slug}</option>
                  ))}
                </datalist> */}

                <small>Descripción(opcional)</small>
                <input 
                  type='text'
                  placeholder='Descripción'
                  name='description'
                  id='voucher_input_4'
                  value={description}
                  onChange={e => handleChange(setVoucher,e)}
                />
                <div className="voucher_input_container ot_watchmaker_input_to_derive checkbox">
                  <input 
                    type='checkbox'
                    name='active'
                    id='voucher_input_5'
                    value={active}
                    onChange={e => handleChange(setVoucher,e)}
                  />
                  <label htmlFor="voucher_input_5">Estado activo</label>
                </div>

              </div>
          </div>
          <div className='ot_button_group_1'>
          
            <button
							onClick={submit}
              className='ot_create_button green'
            > 
              <small>Crear Voucher OT</small>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault()
                setVoucher(voucherState)
              }}
              className='ot_create_button red1'
            > 
              <small>Vaciar formulario</small>
            </button>
          </div>
          <br />

          <ConfirmationModal
            {...aux}
          />
      </form>
  )

}

export default OTCreateVoucherForm
