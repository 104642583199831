import { useFiltroReportes } from "../../hooks";
import React, { useState } from "react";
import { Datepicker } from "vendor/forms";
import { Tooltip } from "vendor/tooltip";
import { useEffect } from "react";
import { useDispatch } from "vendor/react-redux";

export const LayoutFilter = () => {
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const { setRangeInStore, submit, restart } = useFiltroReportes(
    setDateRange,
    dispatch
  );

  useEffect(() => {
    // @TODO: Ver como mejorar el sistema de filtrado
    const rango = JSON.parse(localStorage.getItem("reportes-rango"));
    if (rango?.length) {
      setDateRange(() =>
        rango.map((fecha) => new Date(`${fecha}T00:00:00-03:00`))
      ); // Hora Buenos Aires, Argentina
      localStorage.setItem("reportes-submit", true);
    } else {
      localStorage.setItem("reportes-submit", false);
    }
  }, []);
  return (
    <div className="layout-filter">
      <Datepicker
        selectsRange
        selected={startDate}
        startDate={startDate}
        endDate={endDate}
        onChange={setRangeInStore}
        isClearable
        maxDate={new Date()}
        showDisabledMonthNavigation
        dateFormat="dd/MM/yyyy"
      />
      <Tooltip place="bottom" content="Filtrar reportes por rango">
        <button type="button" className="layout-filter-submit" onClick={submit}>
          <i className="fas fa-search" />
          <i className="fas fa-angle-double-right" />
        </button>
      </Tooltip>
      <Tooltip place="bottom" content="Deshacer filtros por rango">
        <button type="button" className="layout-filter-undo" onClick={restart}>
          <i className="fas fa-undo" />
        </button>
      </Tooltip>
    </div>
  );
};
