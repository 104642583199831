import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { otGetToAssignInfo } from "../../../actions/ot/ot-get";
import { formatDateWithTime } from "../../../helpers/helpers";
import { isCS, isWatchmaker } from "../../../helpers/permission";
import { Loader } from "../../Loader/Loader";
import { OTRowOptions } from "./OTRowOptions";
import { OTUpdates } from "../OTDetails/OTUpdates";
import { deleteOtModalComments } from "../../../actions/ot/ot-comments";
import { useLocation } from "react-router-dom";
import { PATHS } from "../../../types/paths";

export const OTDatatable = ({ filterText }) => {
  let auxTickets, filteredItems;

  const history = useHistory();
  const { pathname } = useLocation();
  const { name: role } = useSelector((state) => state.auth.role);
  const { tickets } = useSelector((state) => state.storage);
  const { loading } = useSelector((state) => state.ui);

  const [otUpdates, setOtUpdates] = useState({ status: false, info: null });

  const dispatch = useDispatch();

  if (tickets.length > 0) {
    auxTickets = tickets.map((ticket) => {
      return {
        slug: ticket.rma && ticket.rma.slug ? ticket.rma.slug : ticket.slug,
        title: ticket.title,
        brand: ticket.rma?.swatch_object?.brand?.name || "-",
        //topic: (ticket.topic ? ticket.topic.name : '-'),
        user: ticket.user ? ticket.user.username : "-",
        updated_at: formatDateWithTime(ticket.updated_at),
        created_at: formatDateWithTime(ticket.created_at),
        //priority: ticket.priority.name,
        status: ticket.status.name,
        //is_taken: ticket.is_taken,
        //agent: ticket.agent
      };
    });

    //console.log(auxTickets[0].slug, "slug")
    filteredItems = auxTickets.filter(
      (item) =>
        (item.slug && item.slug.toString().includes(filterText)) ||
        (item.title &&
          item.title.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.brand &&
          item.brand.toLowerCase().includes(filterText.toLowerCase())) ||
        //|| (item.topic && item.topic.toLowerCase().includes(filterText.toLowerCase()))
        (item.user &&
          item.user.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.created_at && item.created_at.includes(filterText)) ||
        (item.updated_at && item.updated_at.includes(filterText)) ||
        //|| (item.priority && item.priority.toLowerCase().includes(filterText.toLowerCase()))
        (item.status &&
          item.status.toLowerCase().includes(filterText.toLowerCase()))
    );
  }

  const colsOt = [
    {
      grow: 1,
      name: "#",
      selector: "slug",
      sortable: true,
    },
    {
      grow: 1,
      name: "Asunto",
      selector: "title",
      sortable: true,
    },
    {
      grow: 1,
      name: "Usuario",
      selector: "user",
      sortable: true,
    },
    {
      grow: 1,
      name: "Marca",
      selector: "brand",
      sortable: true,
    },
    {
      grow: 1,
      name: "Creado",
      selector: "created_at",
      sortable: true,
    },
    {
      grow: 1,
      name: "Modificado",
      selector: "updated_at",
      sortable: true,
    },
    {
      grow: 1,
      name: "Estado",
      selector: "status",
      sortable: true,
    },
    {
      grow: 1,
      name: "Opciones",
      selector: "options",
      cell: (row) => (
        <OTRowOptions
          row={row}
          setOtUpdates={setOtUpdates}
          otUpdates={otUpdates}
        />
      ),
    },
  ];

  const pagOpts = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItems: true,
    selectAllRowsItemText: "Todos",
  };

  const customStyles = {
    rows: {
      style: {
        fontSize: ".7rem",
        cursor: isCS(role) ? "pointer" : "default",
        "&:hover": {
          background: "rgba(114, 107, 121, 0.2)",
        },
      },
    },
  };

  useEffect(() => {
    if (
      isCS(role) &&
      (pathname.includes("repaired") || pathname.includes("panel/ot/ticket"))
    ) {
      dispatch(otGetToAssignInfo("ot-to-technical"));
    }
  }, [dispatch, role, pathname]);
  return (
    <>
      <DataTable
        columns={colsOt}
        data={filteredItems}
        pagination={filteredItems && filteredItems.length < 10 ? false : true}
        paginationComponentOptions={pagOpts}
        onRowClicked={(row) => history.push(`/panel/ot/${row.slug}/detail`)}
        customStyles={customStyles}
        noDataComponent="No hay registros"
        //conditionalRowStyles={conditionalRowStyles}
        progressPending={loading}
        progressComponent={<Loader />}
      />

      <OTUpdates
        show={otUpdates}
        onHide={() => {
          setOtUpdates({ status: false, info: null });
          return dispatch(deleteOtModalComments());
        }}
      />
    </>
  );
};
