import makeRequest from '../../../../actions/makeRequest'
import { otGetVoucherInfo } from '../../../../actions/ot/ot-voucher'
import { justDate } from '../../../../helpers/helpers'
import { strGenerator } from '../../../../lib/randomstring'
import { postRequest } from './requests'
import { errorState, voucherState } from './states'

export const generateID = (stateFn, e) => {
    e.preventDefault()
    e.stopPropagation()
    return stateFn(v => ({
      ...v, 
      code: strGenerator()
    }))
  }

export const handleChange = (stateFn, e) => {
    const {name,value,checked} = e.target
    if(name === 'active') {
      return stateFn(v => ({
        ...v, 
        [name]:checked
      }))
    }
    return stateFn(v => ({
      ...v,
      [name]:value
    }))
  }

export const submitValidation = ({voucher,rmaCollection}) => {

  const {
    code,
    rma_id,
  } = voucher

  if( !code ) return {status:true, message:'Genere un código único de voucher'}
  if( !rma_id ) return {status:true, message:'Es necesario asignar un OT'}

  //const auxSlug = Number(rma_id.match(/\d+/g)[0])
  // const filteredSlug = rmaCollection.filter(rma => rma.slug === auxSlug)

  // if( auxSlug && filteredSlug.length !== 1 ) return {status:true, message:'Es necesario que la OT sea válida'}
  return false
}

export const submitFunction = ({ voucher, rmaCollection, setVoucher, setError, dispatch }) => {
  const {
    date_from,
    date_to,
    description,
    rma_id,
  } = voucher
  
  const auxSlug = Number(rma_id.match(/\d+/g)[0])

  setError(errorState)

  postRequest.data = {
    ...voucher, 
    rma_id : auxSlug,
    description: description.length ? description : `Descripción por defecto de voucher para OT ${auxSlug}`,
    date_from:justDate(date_from,true),
    date_to:justDate(date_to,true),
  }

  return new Promise((resolve) => {
    const aux = dispatch(makeRequest(postRequest))
    setVoucher(voucherState)
		dispatch(otGetVoucherInfo());
    resolve(aux)
  })
}
