import React, { useEffect } from "react";
import { useDispatch } from "vendor/react-redux";
import {
  LayoutNavbar,
  LayoutChart,
  LayoutFilter,
  LayoutForms,
} from "../components";
import { useDeleteReportes, useGetReportes } from "../hooks";

export const Reportes = () => {
  const dispatch = useDispatch();
  const { getReportes } = useGetReportes();
  const { reportesBorrar } = useDeleteReportes();

  useEffect(() => {
    dispatch(getReportes());
    return () => dispatch(reportesBorrar());
  }, [dispatch, getReportes, reportesBorrar]);

  return (
    <div className="layout-inner">
      <div className="layout-inner-left">
        <div className="panel_cliente_titulo">
          <h2>Reportes</h2>
        </div>
        <LayoutFilter />
        <LayoutForms />
      </div>
      <div className="layout-inner-right">
        <LayoutNavbar />
        <LayoutChart />
      </div>
    </div>
  );
};
