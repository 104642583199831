//Permisos por grupo:
//Roles
const ROLES = Object.freeze({
    //Tipo admin
    admin:'Administrador',
    company_admin:'Administrador de Empresa',
    //Tipo agent
    supervisor:'Supervisor',
    agent:'Agente',
    sub_agent:'Sub Agente',
    //Tipo client
    client:'Cliente',
    pdv:'Punto Venta',
    cs:'Customer Service',
    watch:'Relojero',
    //Tipo external
    external:'Externo',
});

const ROLE_TYPES = Object.freeze({
    admin:'admin', 
    agent:'agent', 
    client:'client', 
    external:'external'
});



//Permisos por grupo:

export const hasPanelAccess = role => {
    switch (role) {
        case ROLES.admin:
            return true;
            
        case ROLES.company_admin:
            return true;

        case ROLES.supervisor:
            return true;

        case ROLES.agent:
            return true;

        case ROLES.sub_agent:
            return true;
            
        default:
            return false;
    }
}
/**By role type */
//TYPE admin
export const roleTypeAdmin = (role,type) => type === ROLE_TYPES.admin && (role === ROLES.admin || role === ROLES.company_admin)  ? true : false;

//TYPE agent
export const roleTypeAgent = (role,type) => type === ROLE_TYPES.agent && (role === ROLES.supervisor || role === ROLES.agent || role === ROLES.sub_agent)  ? true : false;

//TYPE client
export const roleTypeClient = (role,type) => type === ROLE_TYPES.client && (role === ROLES.cs || role === ROLES.pdv || role === ROLES.watch || role === ROLES.client)  ? true : false;

//TYPE external
export const roleTypeExternal = (role,type) => type === ROLE_TYPES.external && (role === ROLES.supervisor || role === ROLES.agent || role === ROLES.sub_agent)  ? true : false;



export const hasHighAccess = role => (role === ROLES.admin || role === ROLES.supervisor || role === ROLES.company_admin)  ? true : false;

export const isPDVorCS = role => (role === ROLES.pdv || role === ROLES.cs)  ? true : false;

//Permisos individuales
export const isAdmin = role => role === ROLES.admin ? true : false;
export const isCompanyAdmin = role => role === ROLES.company_admin ? true : false;
export const isSupervisor = role => role === ROLES.supervisor ? true : false;
export const isAgent = role => role === ROLES.agent ? true : false;
export const isSubAgent = role => role === ROLES.sub_agent ? true : false;

export const isPDV = role => role === ROLES.pdv ? true : false;
export const isCS = role => role === ROLES.cs ? true : false;
export const isClient = role => role === ROLES.client ? true : false;
export const isWatchmaker = role => role === ROLES.watch ? true : false;

export const isExternal = role => role === ROLES.external ? true : false;


