import Axios from "axios"
import { TYPES } from "../../types/types";
import { setHeader } from "../header";
import { setLastDispatch } from "../last-dispatch/last-dispatch";
import { uploadTickets } from "../tickets";
import { isLoading, isNotLoading } from "../ui";

export const otGetInfo = (type,params = null) => {
  return async(dispatch) => {
        dispatch(isLoading());
        dispatch(setLastDispatch(type,'ot'))
        await Axios.get(`api/rma/list/${type}`, {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            params:{
              'ready_deliver':(type === 'transit-to-deliver' || type === 'from-cs') ? 1 : 0,
              ...params
            }
        })
        .then(res => {
            dispatch( setHeader(res.data.title, '') );
            const { status, tickets } = res.data;
            if(status === 'success') {
              dispatch( uploadTickets(tickets));
              return dispatch(isNotLoading());
            } else {
              //console.log('Error en data: ', res.data);
              return dispatch(isNotLoading());
            }
        })
        .catch(err => {
          //console.log(err)
          dispatch(isNotLoading());
            //alert('Falló la búsqueda de información sobre OT, intente nuevamente: ', err);
        })
  }
}

export const otGetToAssignInfo = (code) => {
  return async(dispatch) => {
        dispatch(isLoading());
        await Axios.get(`api/rma/${code}`, {
          headers:{
              'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        })
        .then(res => {
          //console.log(res.data)
          dispatch(otRoleToDistribute(res.data.list))
          dispatch(isNotLoading());
        })
        .catch(err => {
          //console.log(err)
          dispatch(isNotLoading());
        })
  }
}

export const otGetObservationsListWatchmaker = slug => {
  return async(dispatch) => {
        dispatch(isLoading());
        await Axios.get(`api/rma/${slug}/observations-list`, {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then(res => {
          dispatch(otWatchmakerObservationsList(res.data.observations,res.data.ticket))
        })
        .catch(err => {
          //console.log(err)
          dispatch(isNotLoading());
        })  
  }
}

export const otGetWatchmakersListToDerive = slug => {
  return async(dispatch) => {
        dispatch(isLoading());
        await Axios.get(`api/rma/watchmakers-list`, {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then(res => {
          dispatch(otSetWatchmakersListToDerive(res.data.watchmakers,res.data.ticket))
        })
        .catch(err => {
          //console.log(err)
          dispatch(isNotLoading());
        })  
  }
}


const otRoleToDistribute = info => ({ type: TYPES.otRoleToDistribute, payload: info })
const otWatchmakerObservationsList = (obs,ticket) => ({ type: TYPES.otWatchmakerObservationsList, payload:{observations:obs,ticket} })
const otSetWatchmakersListToDerive = (watchmakers,ticket) => ({ type: TYPES.otWatchmakerList, payload:{watchmakers,ticket} })

export const otDeleteDistInfo = info => ({type: TYPES.otDeleteDistInfo})