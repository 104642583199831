import { Button, Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createOrUpdateClientOTTicket } from '../../../../../actions/ot/ot-create';
import ReactToPdf from 'react-to-pdf'
import { OTPDFReady } from '../../OTExport/OTPDFReady';

export const OTConfirmationModal = (props) => {
  const initialOtMessage = {status:'',message:'',show:false}
  const [otMessage, setOtMessage] = useState(initialOtMessage)
  const [readyPdf, setReadyPdf] = useState(false)
  const {pathname} = useLocation()
  const ot = useSelector(state => state.ot);
    /* {detail, rma, rmaObject, deliver} */

  const history = useHistory();
  const dispatch = useDispatch();
  const toSend = () => {
    dispatch( createOrUpdateClientOTTicket( 
      props.show?.info,
      setOtMessage,
      pathname.includes('edit') ? 'edit' : 'create',
      setReadyPdf
    ))
  }
  const handleGoBack = () => {
    setTimeout(() => {
      setReadyPdf(false)
    },5000)
    setOtMessage(initialOtMessage)
    props.onHide()
  }

  const ref = React.createRef();

  const shownMessage = () => {
    return otMessage.show ? otMessage.message : `Para finalizar la ${pathname.includes('edit') ? 'actualización': 'creación'} de la OT, es necesario que haya rectificado todos los datos ingresados; si está conforme, por favor confirme el envío de los datos al servidor para completar el proceso de ${pathname.includes('edit') ? 'actualización': 'creación'} de la OT.`
  }
  /* useEffect(() => {
    if(otMessage.status === 'creado' || otMessage.status === 'actualizado'){
      setTimeout(() => {
        history.push('/panel/ot/ticket')
      },2500)
    }

    if(otMessage.status === 'problema'){
      setTimeout(() => {
        props.onHide()
      },3000)
    }
  },[history, otMessage.status, props]) */
  useEffect(() => {
    if(ot.detail?.title && ot.detail.title.includes('OT N')){
      setReadyPdf(true)
    }
  }, [ot.detail.title])
  return (
     <Modal
      show={props.show && props.show.status}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop={false}
    >
      <Modal.Header closeButton className='ot_confirmation_top'>
        <Modal.Title id="contained-modal-title-vcenter">
          {pathname.includes('edit') ? 'Actualización': 'Creación'} de OT
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='ot_confirmation_body'>
        <h6>Confirmación:</h6>
        <p className='pl-2'>
          { shownMessage() }
        </p>
        {
          readyPdf &&
          <OTPDFReady
            ot={ot}
            auxRef={ref}
          />
        }
      </Modal.Body>
      <Modal.Footer className='ot_confirmation_bottom'>
        {
          readyPdf &&
          <ReactToPdf targetRef={ref} filename={`${ot.rma?.slug}-ot-document.pdf`}>  
            {({toPdf}) => (
              <>
                <Button variant="danger" 
                  onClick={auxComponent => {
                    setTimeout(()=>{
                      return history.push('/panel/ot/ticket')
                    },5000)
                    setOtMessage(initialOtMessage)
                    return toPdf(auxComponent)
                  }}>
                  <i className="far fa-file-pdf"></i> Descargar PDF
                </Button>
                <Button variant="info" 
                  onClick={() => {
                    return history.push('/panel/ot/ticket')
                  }}
                >
                  Regresar al panel
                </Button>
              </>
            )}
          </ReactToPdf>
        }
        {!readyPdf && <Button variant="success" onClick={toSend}>Enviar</Button>}
        <Button variant="outline-danger" onClick={() => {
          handleGoBack()
            /* setTimeout(()=>{
              return history.push('/panel/ot/ticket')
            },5000) */
          }}>Volver</Button>
      </Modal.Footer>
    </Modal>
  
  )
}
