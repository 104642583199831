import { isCS } from "helpers/permission";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { TicketDataFilter } from "../../components/Clients/ClientTicket/TicketTable/TicketDataFilter";
import { Loader } from "../../components/Loader/Loader";
import { OTNavbar } from "../../components/OT/OTClientTickets/OTNavbar";
import { OTDatatable } from "../../components/OT/OTDatatable/OTDatatable";
import OTVoucherDatatable from "../../components/OT/OTDatatable/OTVoucherDatatable";
import { OTMassiveDistribution } from "../../components/OT/OTDragNDrop/OTMassiveDistribution/OTMassiveDistribution";
import { OTFormsComponent } from "../../components/OT/OTForms/OTFormsComponent";

const OtTicketsIndex = () => {
  const location = useLocation();

  const { title } = useSelector((state) => state.header);
  const { loading } = useSelector((state) => state.ui);
  const { name: role } = useSelector((state) => state.auth.role);

  const [filterText, setFilterText] = useState("");

  return (
    <div className="panel_cliente_marco_interno">
      <div className="panel_cliente_izquierda">
        <div className="panel_cliente_titulo">
          <h2>{loading ? <Loader /> : title}</h2>
        </div>
        <TicketDataFilter
          filterText={filterText}
          setFilterText={setFilterText}
        />
        <OTFormsComponent />
      </div>
      <div className="panel_cliente_derecha ot">
        <div className="panel_cliente_tipo_ticket">
          <OTNavbar />
        </div>
        <div className="panel_cliente_tabla">
          {location.pathname.includes("panel/ot/distribution") ? (
            <OTMassiveDistribution />
          ) : location.pathname.includes("panel/ot/vouchers") && isCS(role) ? (
            <OTVoucherDatatable filterText={filterText} />
          ) : (
            <OTDatatable filterText={filterText} />
          )}
        </div>
      </div>
    </div>
  );
};

export default OtTicketsIndex;
