import React, { useState } from 'react'
import { useDrop } from 'react-dnd'
import { onlyUnique } from '../../../../helpers/helpers'
import { dndTypes } from '../../../../types/dnd'
import { OTDragCard } from './OTDragCard'

export const OTDropTo = ({ticketList}) => {/* 
  const [dropList, setDropList] = useState([]) */
/* console.log('dropto',ticketList) */
  const [{isOver}, drop] = useDrop({
    accept: dndTypes.CARD,
    drop:(item,monitor) => {
      
      
      /* 
      if(dropList.filter( ticket => (ticket.title === item.title) && (ticket.slug === item.slug)).length > 0) {
        return;
      } else {
        setDropList([...dropList, item.ticket ])
      } */
    },
    collect: monitor => ({
      isOver: !!monitor.isOver()
    })
  })
  return (
    <ul
      className='ot_drop_to'
      ref={drop}
      style={{
        backgroundColor:`${isOver ? 'var(--input-border)' : '#fff'}`,
        transition:`${isOver ? '.35s all ease-in-out' : '0'}`
      }}
    >
      {(ticketList && ticketList.length > 0 ) 
      && ticketList
      .filter(onlyUnique)
      .filter(ticket => ticket.aux_status === 'passed')
      .map((ticket,index) => {
        return (
          <OTDragCard
            ticket={ticket} 
            key={index}
          />
        ) 
      })}
    </ul>
  )
}
