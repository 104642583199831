import { TYPES } from "../types/types";

const initialState = {
    tickets: [],
}

export const contentReducer = ( state = initialState, action ) => {

    switch (action.type) {
        
        /**TICKETS */
        case TYPES.setTickets:
            return {
                ...state,
                tickets: [...action.payload]
            };

        case (TYPES.deleteTickets || TYPES.otDeleteDistInfo):
            return {
                ...state,
                tickets:[]
            };
            
        case TYPES.deleteContent:
            return { ...initialState }

        default:
            return state;
    }

}
