import { TYPES } from "../types/types";

const initialState = {
  code:"", type:""
}

export const lastDispatchReducer = (state = initialState, action) => {

    switch (action.type) {

      case TYPES.setLastDispatch:
        return { ...state, ...action.payload}

      case TYPES.deleteLastDispatch:
        return { ...initialState }

        default:
            return state;
    }
}