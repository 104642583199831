import React, { useState } from 'react'
import { useDispatch } from 'react-redux';

import { errorState, modalState, updateVoucherState } from './states';
import ConfirmationModal from '../../../../components_new/modals/SimpleConfirmationModal';
import { handleChange, submitFunction, submitValidation } from './events';


const OTConsumeVoucherForm = () => {
	const [voucher, setVoucher] = useState(updateVoucherState);
  const [error, setError] = useState(errorState);
  const [modal, setModal] = useState(modalState);
  const dispatch = useDispatch(); 

  const {
    code,
    rma_id,
    client_id
  } = voucher

	const submitSetup = {voucher, setVoucher, setError, dispatch}

  const aux = {
      show: modal.modalShow,
      onHide:() => setModal({
        ...modal,
        modalShow: false,
      }),
      fnToInvoke: () => submitFunction(submitSetup),
      modalSize : 'md',
      backdrop : 'static',
			modalText: 'Para confirmar el envío de información para consumir el Voucher OT, por favor presione en -Enviar-'
  }

	const submit = e => {
		e.preventDefault()
		const {status, message} = submitValidation(voucher)
		if(status) {
			return setError({status,message})
		}
		
		setModal({
			...modal,
			modalShow: true,
		})
	}


  return (
      <form className='ot_search_form'>

          <div>
              <p className="panel_cliente_crear_ticket_nuevo">Consumir Voucher OT</p>
              <small className={ 'panel_cliente_crear_ticket_aclaracion ' + (error.status && 'error_color')}>
                  {
                      error.status ? error.message : 'Ingrese los datos correspondientes para consumir un Voucher OT'
                  }
              </small>
              <div className="panel_cliente_crear_ticket_inputs">
                
                <div className="voucher_input_container ot_watchmaker_input_to_derive">
                  <label htmlFor='voucher_input_1'><small>Código del voucher</small></label>
                  <input 
                    type='text'
                    placeholder='3XAMPL30FC0D3'
                    name='code'
                    value={code}
                    id='voucher_input_1'
                    onChange={e => handleChange(setVoucher,e)}
                  />
                </div>
                
                <small>OT del voucher</small>
                <input 
                  type='text'
                  placeholder='# OT'
                  name='rma_id'
                  id='voucher_input_6'
                  value={rma_id}
                  onChange={e => handleChange(setVoucher,e)}
                />
                <small>ID del cliente</small>
                <input 
                  type='text'
                  placeholder='ID Cliente'
                  name='client_id'
                  id='voucher_input_6'
                  value={client_id}
                  onChange={e => handleChange(setVoucher,e)}
                />

                <div className="voucher_input_container ot_watchmaker_input_to_derive checkbox">
                </div>

              </div>
          </div>
          <div className='ot_button_group_1'>
            
            <button
              onClick={submit}
              className='ot_create_button green'
            > 
              <small>Consumir Voucher OT</small>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault()
                setVoucher(updateVoucherState)
              }}
              className='ot_create_button red1'
            > 
              <small>Vaciar formulario</small>
            </button>
          </div>
          <br />
          <ConfirmationModal
            {...aux}
          />
      </form>
  )

}

export default OTConsumeVoucherForm
