import { generate } from 'randomstring'

/**
 * Adaptador de librería -randomstring- para generar
 * un string aleatorio con letras mayúsculas
 * con un length por default de 10
 * @param {Number} length Longitud del string
 * @returns {String} String aleatorio
 */

const strGenerator = (length = 10) => {
	const aux = generate(length).toUpperCase()
	return typeof aux === 'string' && aux.length === length && aux
}

export default strGenerator
