import { justDate } from "helpers/helpers";
import { apiInstance } from "vendor/axios";

const CONFIG = {
  url: "api/reportes/ot-export-report",
  method: "POST",
};

const formatData = ({ range, extension }) => ({
  range: range[0] ? range.map((date) => justDate(date, true)).join(" - ") : "",
  extension,
});

const setConfig = (data) =>
  data ? { ...CONFIG, data: formatData(data) } : CONFIG;

export const useExportarReportes = (useState) => {
  const requestConfig = apiInstance();
  const [key, setKey] = useState("try");
  const [modalShow, setModalShow] = useState(false);
  const [form, setForm] = useState({ range: [null, null], extension: "csv" });
  const {
    extension,
    range: [startDate, endDate],
  } = form;

  const onChange = (e) =>
    Array.isArray(e)
      ? setForm((state) => ({ ...state, range: e }))
      : setForm((state) => ({ ...state, [e.target.name]: e.target.value }));

  const closeConfirmationModal = (e) => {
    if (e != null) e.preventDefault();
    setModalShow(false);
  };

  const openConfirmationModal = (e) => {
    setModalShow(true);
  };

  // @TODO: Ver como mejorar este tipo de funciones
  const postExportInfo = async (e) => {
    if (e != null) e.preventDefault();

    return await requestConfig
      .request(setConfig(form))
      .then(({ data }) => {
        setForm((state) => ({ ...state, data }));
        openConfirmationModal();
      })
      .catch(() => console.log("fail"));
    // @TODO: Agregar manejo de errores
  };
  return {
    key,
    setKey,
    modalShow,
    postExportInfo,
    closeConfirmationModal,
    openConfirmationModal,
    onChange,
    form,
    extension,
    startDate,
    endDate,
  };
};
