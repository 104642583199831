import React from "react";

export default {
  bar: React.lazy(() =>
    import(
      /* webpackChunkName: "Bar Chart Component" */ "vendor/charts/types/bar-chart"
    )
  ),
  "line-bar-area": React.lazy(() =>
    import(
      /* webpackChunkName: "Line Bar Area Chart Component" */ "vendor/charts/types/line-bar-area-composed-chart"
    )
  ),
  "pie-with-padding": React.lazy(() =>
    import(
      /* webpackChunkName: "Pie With Padding Chart Component" */ "vendor/charts/types/pie-with-padding-chart"
    )
  ),
};
