import React, { Suspense } from "react";
import charts from "./charts";
import { Switch, Route } from "vendor/react-router-dom";
import { useSelector } from "react-redux";

export const LayoutChart = () => {
  const chartsData = useSelector((state) => state.reportes.charts) || {};
  return (
    <div className="layout-chart">
      <Suspense fallback={<div>...</div>}>
        <Switch>
          {charts.map(({ to, id, Component, ...attributes }) => (
            <Route
              path={to}
              key={id}
              component={() => (
                <Component code={id} data={chartsData[id]} {...attributes} />
              )}
            />
          ))}
          <Route path={charts[0].to} component={charts[0].Component} />
        </Switch>
      </Suspense>
    </div>
  );
};
