import Axios from "axios";
import { TYPES } from "../../types/types";
import { startLogout } from "../auth";
import { setHeader } from "../header";
import { authError, error, isLoading, isNotLoading } from "../ui";

export const setProfileInfo = () => {
    return async(dispatch) => {
        dispatch(isLoading());

        await Axios.get('api/user',{
            headers:{
                'Authorization':'Bearer ' + localStorage.getItem('token')
            }
        })
            .then( res => {
                //console.log('tareas del server',res.data)
                dispatch( uploadUserInfo(res.data.data.user) );
                dispatch( setHeader(res.data.data.title, res.data.data.description) );
                dispatch( isNotLoading() );
            })
            .catch( err => {

                dispatch(error('Parece que no se puede procesar la petición de información del usuario'));
                dispatch( isNotLoading() );
            })
        }
}

export const sendProfileInfo = info => {
    return async(dispatch) => {
        dispatch(isLoading());

        await Axios.post('api/user', info, {
            headers:{
                'Authorization':'Bearer ' + localStorage.getItem('token')
            }
        })
            .then( res => {
                //console.log('respuesta del server',res.data.message);
                dispatch( isNotLoading() );
                if(res.data.message === 'Datos y contraseña actualizados') {
                    dispatch(startLogout());
                    dispatch(authError('Por favor ingrese nuevamente con los datos actualizados'));
                }
            })
            .catch( err => {
                //console.log('err', err);
                dispatch(error('Parece que no se puede procesar la petición de información del usuario'));
                dispatch( isNotLoading() );
            })
        }
}

const uploadUserInfo = info => ({
    type: TYPES.setUserProfile,
    payload: info
});

export const deleteUserInfo = () => ({
    type: TYPES.deleteUserInfo
});