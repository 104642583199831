import React, { useEffect, useState } from 'react'
import Tippy from '@tippyjs/react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { isCS, isWatchmaker } from '../../../helpers/permission';
import { useForm } from '../../../hooks/useForm';
import { loadRma, updateRma } from '../../../actions/ot/ot-detail';
import { otWatchmakerTakeOrRelease } from '../../../actions/ot/ot-update';
import { OTWatchmakerSetReady } from './OTWatchmakerSetReady';
import { OTWatchmakerDerive } from './OTWatchmakerDerive';
import { OTCheckBudget } from './OTCheckBudget';
import { OTSimple } from './OTSimple';
import { OTVoucher } from './OTVoucher';
import { otGetToAssignInfo, otGetWatchmakersListToDerive } from '../../../actions/ot/ot-get';

export const OTCommentBox = ({slug, status, rma}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {name:role} = useSelector(state => state.auth.role)
  const [error, setError] = useState({
    status:false,
    message:''
  });
  const initialValue = {
    comment: '',
    action:'comment',
    ticketFile:null
  }
  
  const [ value, handleInputChange, actualValue, reset ] = useForm(initialValue)

  const handleClick = async(e) => {
    e.preventDefault();
    let 
      buttonValue = '',
      fileInput
    if(e.target.innerHTML) {
      buttonValue = e.target.value;
    } else {
      buttonValue = e.target.parentNode.value;
    }
    const body = { target : { name: 'action', value: buttonValue } } 
    handleInputChange(body)

    const {
      action:a,
      comment:b,
      ticketFile:c
    } = await actualValue();
    
    if( !a ) {
      return setError({status:true, message:'Existe un problema con el sistema [action]'})
    } 

    /* if(a !== 'deliver' && !b) {
      return setError({status:true, message:'Es necesario escribir un comentario'})
    } */
    if(a === 'comment' && !b) {
      return setError({status:true, message:'Es necesario escribir un comentario'})
    }

    if(a === 'budget' && !c) {
      return setError({status:true, message:'Es necesario adjuntar el presupuesto'})
    }

    setError({status:false, message:''})
    
    let fd = new FormData();
    if(c) {
        fileInput = document.querySelector('#otFileStorage') 
        fd.append('ticketFile', c);
    }
    fd.append('action', a);
    fd.append('comment', b);
    
    await dispatch(updateRma(slug, fd));  
    
    reset()
    if(c) {
      fileInput.value = ''
    }
    if(isCS(role) || isWatchmaker(role)) {
      return dispatch(loadRma(slug))
    }
    
  }

  const handleChange = e => {
    if(e.target.name === 'ticketFile') {
      setError({status:false, message:''})
      handleInputChange({
            target: {
                name :'ticketFile',
                value : e.target.files[0]
            }            
        })
    }
    if(e.target.name === 'comment') {
      handleInputChange({
            target: {
                name :'comment',
                value : e.target.value
            }            
        })
    }
  }

  const watchmakerToR = async(e) => {
    e.preventDefault()
    const fd = new FormData()
    if(status.name && status.name === 'En revision') {
      fd.append('action','release')
      fd.append('id',`${slug}`)
    } else{
      fd.append('action','take')
      fd.append('id',`${slug}`)
    }
    await dispatch(otWatchmakerTakeOrRelease(fd))
    return dispatch(loadRma(slug))
  }


  const [modalShow, setModalShow] = useState({status:false,info:null});
  const [deriveShow, setDeriveShow] = useState({status:false,info:null});
  const [checkBudget, setCheckBudget] = useState({status:false,info:null});
  const [simple, setSimple] = useState({status:false,info:null});
  const [voucher, setVoucher] = useState({status:false,info:null});

  const handleSetReady = async(e) => {
    e.preventDefault();
    setModalShow({status:true,info:''})
  }
  const handleDerive = async(e) => {
    e.preventDefault();
    setDeriveShow({status:true,info:''})
  }
  const handleCheck = async(e) => {
    e.preventDefault();
    setCheckBudget({status:true,info:''})
  }
  const handleSimple = async(e) => {
    e.preventDefault();
    setSimple({status:true,info:''})
  }
  const handleVoucher = async(e) => {
    e.preventDefault();
    setVoucher({status:true,info:{slug}})
  }

  useEffect(() => {
    if(isCS(role) || isWatchmaker(role)) {
      dispatch(otGetToAssignInfo('ot-to-technical'))
    }
  },[dispatch,role])
    return (
      <form 
        className='comment-box-form'
        encType="multipart/form-data"
      >
        
        <>
          <textarea 
              placeholder='Comentario...' 
              value={ value.comment } 
              name='comment'
              onChange={ handleInputChange } 
              id='text-area-ticket-comment'
          >
          </textarea>

          { //Caso de error
              error.status
              && <small style={{color:'red'}}>
                  {error.message}
              </small>
          }

          { //Si está habilitado el ticket
            (isCS(role) || isWatchmaker(role))
            &&
            <div className='comment-box-buttons'>
              <div>
                {
                  ((isCS(role) || isWatchmaker(role)) && status 
                    && (status.name !== 'En transito' || status.name !== 'Entregado')) &&
                  <Tippy content='Hacer un comentario' placement='bottom'>
                    <button 
                      className='comment-submit' 
                      name='action'
                      value='comment' 
                      onClick={ handleClick }
                    >
                      Comentar
                    </button>
                  </Tippy>
                }
                {
                  (isCS(role) && status && 
                    (status.name === 'Presupuesto aceptado' 
                    || status.name === 'En Customer Service' 
                    || status.name === 'Reparacion gratuita')
                  ) && 
                  <Tippy content='Enviar a técnica' placement='bottom'>
                    <button 
                      className='interno-submit internal' 
                      name='action'
                      value='to-technical' 
                      onClick={handleDerive}
                      style={{backgroundColor:'#F5F749', color:'var(--darkcolor-soft)'}}
                    >
                      A Técnica
                    </button>
                  </Tippy>
                }
                {
                  (isCS(role) && status && 
                    (status.name === 'Evaluado' 
                    || status.name === 'Presupuesto rechazado')
                  ) &&
                  <>
                    <Tippy content='Enviar presupuesto a cliente' placement='bottom'>
                      <button 
                        className='interno-submit internal' 
                        name='action'
                        value='budget' 
                        onClick={ handleClick }
                        style={{backgroundColor:'#2E86AB'}}
                      >
                        Presupuestar
                      </button>
                    </Tippy>
                    <Tippy content='Marcar como reparación gratuita' placement='bottom'>
                      <button 
                        className='interno-submit internal' 
                        name='action'
                        value='budgetFree' 
                        onClick={ handleClick }
                        style={{backgroundColor:'#6C698D'}}
                      >
                        Reparación gratuita
                      </button>
                    </Tippy>
                  </>
                }
                {
                  (isCS(role) && status && 
                    (status.name === 'En transito')
                  ) &&
                  <Tippy content='Recibir en CS' placement='bottom'>
                    <button 
                      className='interno-submit internal' 
                      name='action'
                      value='receiveCS' 
                      onClick={ handleClick }
                      style={{backgroundColor:'#276FBF'}}
                    >
                      <i className="fas fa-hand-holding"></i>
                    </button>
                  </Tippy>
                }
                {
                  (isCS(role) && status && 
                    (status.name === 'Cambio'
                    || status.name === 'Plan canje'
                    || status.name === 'Reparado'
                    || status.name === 'Presupuesto rechazado')
                  ) &&
                  <Tippy content='Aprobar OT' placement='bottom'>
                    <button 
                      className='interno-submit internal' 
                      name='action'
                      value='deliver' 
                      onClick={ handleClick }
                      style={{backgroundColor:'#0A2E36'}}
                    >
                      Dar listo
                    </button>
                  </Tippy>
                }
                {
                  (isCS(role) && status && 
                    (status.name === 'Reparado')
                  ) &&
                  <Tippy content='Rechazar reparacion y enviar a técnica' placement='bottom'>
                    <button 
                      className='interno-submit red1' 
                      name='action'
                      value='reject' 
                      onClick={ handleDerive }
                      style={{backgroundColor:'#F03A47'}}
                    >
                      <i className="fas fa-window-close mr-1"></i>Rechazar reparación
                    </button>
                  </Tippy>
                }
                {
                  (isCS(role) && status && 
                    (status.name === 'Presupuestado')
                  ) &&
                  <Tippy content='Revisar presupuesto' placement='bottom'>
                    <button 
                      className='interno-submit internal' 
                      name='action'
                      value='checkBudget' 
                      onClick={ handleCheck }
                      style={{backgroundColor:'#ECD444'}}
                    >
                      <i className="fas fa-coins"></i>
                    </button>
                  </Tippy>
                }
                {
                  (isCS(role) && status && 
                    (status.name === 'Para entrega cliente en PDV'
                    || status.name === 'Para entrega cliente en CS')
                  ) &&
                  <Tippy content='Entregar a cliente' placement='bottom'>
                    <button 
                      className='interno-submit green' 
                      name='action'
                      value='close' 
                      onClick={ handleSimple }
                    >
                      <i className="fas fa-check-circle mr-1"></i>Cerrar OT
                    </button>
                  </Tippy>
                }
                {
                  (isCS(role) && status && 
                    status.name === 'Cambio'
                  ) &&
                  <Tippy content='Crear Voucher' placement='bottom'>
                    <button 
                      className='interno-submit blue2' 
                      name='action'
                      value='close' 
                      onClick={ handleVoucher }
                    >
                      <i className="fas fa-ticket-alt"></i>
                    </button>
                  </Tippy>
                }
                {
                  (isWatchmaker(role)  && status && 
                    (status.name === 'En revision'
                    || status.name === 'En tecnica'
                    || status.name === 'Por revisar'
                    || status.name === 'Reparacion rechazada')
                  ) &&
                  <>
                    <Tippy content='Tomar/soltar OT' placement='bottom'>
                      <button 
                        className={`ot_row_button ${(status.name && status.name === 'En revision') ? 'blue2' : 'yellow3'}`}
                        onClick={watchmakerToR}
                      >
                        {(status.name && status.name === 'En revision') ? <i className="fas fa-hand-rock"></i> : <i className="fas fa-hand-paper"></i> }
                      </button>
                    </Tippy>

                    <Tippy content='Dar listo' placement='bottom'>
                      <button 
                        className='ot_row_button green'
                        onClick={handleSetReady}
                      >
                        <i className="far fa-check-circle"></i>
                      </button>
                    </Tippy>

                    <Tippy content='Derivar OT' placement='bottom'>
                      <button 
                        className='ot_row_button blue1'
                        onClick={handleDerive}
                      >
                        <i className="fas fa-clipboard-check"></i>
                      </button>
                    </Tippy>
                  </>
                }

                <Tippy content="Volver a OT's generales" placement='bottom'>
                  <button 
                    className='interno-submit internal' 
                    onClick={ () => history.push('/panel/ot/ticket') }
                    style={{backgroundColor:'#F24236'}}
                  >
                    Volver
                  </button>
                </Tippy>
                
              </div>
              {
                isCS(role) 
                && <div className="form-group file">
                      {/**INPUT PARA CARGAR ARCHIVO */}
                      <Tippy content='Solo imágenes' placement='bottom'>
                        <input 
                          type="file" 
                          id="otFileStorage"
                          className="form-control-file exampleFormControlFile1" 
                          name='ticketFile'
                          onChange={handleChange}     
                          accept="image/*"                       
                        />
                      </Tippy>
                    </div>
              }
                  
            </div>
          }
        </>
      
        <OTWatchmakerSetReady
          slug={slug}
          show={modalShow}
          onHide={() => setModalShow({status:false,info:null})}
        />
        <OTWatchmakerDerive
          slug={slug}
          status={status}
          show={deriveShow}
          onHide={() => setDeriveShow({status:false,info:null})}
        />
        <OTCheckBudget
          slug={slug}
          show={checkBudget}
          onHide={() => setCheckBudget({status:false,info:null})}
        />
        <OTSimple
          slug={slug}
          status={status}
          show={simple}
          onHide={() => setSimple({status:false,info:null})}
        />
        <OTVoucher
          slug={slug}
          status={status}
          show={voucher}
          onHide={() => setVoucher({status:false,info:null})}
        />
      </form>
    )

}
