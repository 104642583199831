import React, { useState } from "react";

import { OTSearchForm } from "../OTForms/OTSearchForm";
import { Tabs, Tab } from "react-bootstrap";
import { OTTabIcon } from "../OTForms/OTTabIcon";
import { OTReportsForm } from "../OTForms/OTReportsForm";
import { OTWithdrawalForm } from "../OTForms/OTWithdrawalForm";
import OTConsumeVoucherForm from "./OTConsumeVoucherForm";
import OTCreateVoucherForm from "./OTCreateVoucherForm";
import { isCS } from "../../../helpers/permission";
import { useSelector } from "react-redux";
import OTUpdateVoucherForm from "./OTUpdateVoucherForm.js";

export const OTFormsComponent = () => {
  const { name: role } = useSelector((state) => state.auth.role);
  const [key, setKey] = useState("search");
  return (
    <Tabs id="controlled-ot-tabs" activeKey={key} onSelect={(k) => setKey(k)}>
      <Tab
        eventKey="search"
        title={
          <OTTabIcon content={"Búsqueda de OT"} iconClass={"fas fa-search"} />
        }
      >
        <div className="panel_cliente_crear_ticket ot">
          <OTSearchForm />
        </div>
      </Tab>

      <Tab
        eventKey="voucherCreate"
        disabled={!isCS(role)}
        title={
          <OTTabIcon
            content={"Crear Voucher"}
            iconClass={"fas fa-sticky-note"}
          />
        }
      >
        <div className="panel_cliente_crear_ticket ot">
          <OTCreateVoucherForm />
        </div>
      </Tab>

      <Tab
        eventKey="voucherConsume"
        // disabled={!isCS(role)}
        title={
          <OTTabIcon
            content={"Consumir Voucher"}
            iconClass={"far fa-sticky-note"}
          />
        }
      >
        <div className="panel_cliente_crear_ticket ot">
          <OTConsumeVoucherForm />
        </div>
      </Tab>

      <Tab
        disabled
        eventKey="reports"
        title={<OTTabIcon content={"Reportes"} iconClass={"far fa-file-alt"} />}
      >
        <div className="panel_cliente_crear_ticket ot">
          <OTReportsForm />
        </div>
      </Tab>
      <Tab
        disabled
        eventKey="withdraw"
        title={
          <OTTabIcon
            content={"Confirmar retiro de OT"}
            iconClass={"fas fa-sign-out-alt"}
          />
        }
      >
        <div className="panel_cliente_crear_ticket ot">
          <OTWithdrawalForm />
        </div>
      </Tab>
    </Tabs>
  );
};
