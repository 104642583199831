import React from 'react'
// import { formatDateWithTime } from '../../../../helpers/helpers';
const Barcode = require('react-barcode');

const OTVoucher = ({PDFinfo, auxRef}) => {

  const {
    rma_id, 
    code, 
    date_from, 
    date_to, 
    status, 
    isUsed,
    is_external
  } = PDFinfo;


  return (
    <div className='ot_pdf_viewer'>
      <div className='pdf_a4_px_size' ref={auxRef}>
        <div className='header'>
          <figure className='header_logo'>
            <img src={process.env.PUBLIC_URL + '/stylestore_logo.png'} alt='company logo' />
          </figure>
          <div className='header_title'>
            <h3>Registro de Voucher <strong>OT #{rma_id}</strong></h3>
          </div>
        </div>
        <div className='body'>
          <div className='body_ot'>
            <div className='subtitle'>
              <h4>Datos del Voucher OT</h4>
            </div>
            <div>
                <p>Número OT: </p>
                <span>#{ rma_id }</span>
            </div>
            <div>
                <p>Código Voucher: </p>
                <span>{ code }</span>
            </div>
            <div>
                <p>Fecha de inicio: </p>
                <span>{ date_from }</span>
            </div>
            <div>
                <p>Fecha fin: </p>
                <span>{ date_to }</span>
            </div>
            <div>
                <p>Estado: </p>
                <span>{ status ? 'Activo' : 'Inactivo' }</span>
            </div>
            <div>
                <p>Usado: </p>
                <span>{ isUsed ? 'Usado' : 'Sin Usar' }</span>
            </div>
            <div>
                <p>Gestión externa: </p>
                <span>{ is_external ? 'Si' : 'No' }</span>
            </div>
          </div>
        </div>
        <div className='footer'>
          <Barcode 
            value={code}
            height={75}
          />
          <div className='footer_created_by'>
            <span>© Copyright 2022 C-Team - All Rights Reserved </span>
            <span>Jorge 197 Adrogue 1846 Bs As, Argentina</span>
            <span>https://soporte.cteamglobal.com/</span>
            <span>soporte@c-team.com.ar</span>
          </div>
        </div>
      </div>

    </div> 
        
  )
}

export default OTVoucher
