import Axios from "axios";
import { isLoading, isNotLoading } from "../ui";

export const otSendOtListToUpdate = (fd,code) => {
  return async(dispatch) => {
        dispatch(isLoading());

        await Axios.post(`api/rma/${code}`, fd, {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then(res => {
          alert(res.data.message)
          dispatch(isNotLoading());
          return
        })
        .catch(err => {
          alert(err.message)
          return dispatch(isNotLoading());
        })
  }
}

export const otWatchmakerTakeOrRelease = (fd) => {
  return async(dispatch) => {

        await Axios.post(`api/rma/take-or-release`, fd, {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then(res => {
          //console.log(res.data)
          return alert(res.data.message)
        })
        .catch(err => {
          alert(err.message)
          return window.location.reload();
        })
  }
}

export const otUpdateStatus = (fd,code) => {
  return async(dispatch) => {
        await Axios.post(`api/rma/${code}`, fd, {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then(res => {
          //console.log(res.data)
          return alert(res.data.message)
        })
        .catch(err => {
          alert(err.message)
          return window.location.reload();
        })
  }
}


export const otSendWatchmakerUpdate = (fd,slug,code) => {
  return async(dispatch) => {
        dispatch(isLoading());

        await Axios.post(`api/rma/${slug}/${code}`, fd, {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then(res => {
          alert(res.data.message)
          dispatch(isNotLoading());
          return window.location.href = '/panel/ot/ticket';
        })
        .catch(err => {
          alert(err.message)
          return dispatch(isNotLoading());
        })
  }
}
