import Tippy from "@tippyjs/react";
import React from "react";
import { useHistory } from "react-router-dom";
import { justDate } from "../../../helpers/helpers";
import { isCS, isPDV, isWatchmaker } from "../../../helpers/permission";

export const OTSearchStatus = ({ list, role }) => {
  const history = useHistory();

  const handleClick = (slug) => {
    if (slug) {
      return history.push(`/panel/ot/${slug}/detail`);
      // if (isWatchmaker(role) || isCS(role))
        
      /* if (isCS(role)) return window.open(`/panel/ot/${slug}/detail`) */
    }
  };

  const showNotification = (ticket) => {
   // if (!isPDV(role)) {
      if (ticket.rma?.slug) {
        return <i className="fas fa-external-link-alt"></i>;
      }
      return (
        <Tippy
          content="Parece haber un problema con el ID del OT"
          placement="right"
        >
          <i className="fas fa-question-circle text-warning"></i>
        </Tippy>
      );
    //}
    //return <span></span>;
  };
  return (
    <ul className="ot_status">
      {list &&
        list?.length > 0 &&
        list.map((ticket) => (
          <li
            key={ticket.id}
            onClick={() =>
              ticket?.rma && ticket?.rma?.slug && handleClick(ticket?.rma?.slug)
            }
          >
            <span>
              {(ticket.rma?.slug && `#${ticket.rma?.slug}`) || "No ID"}
            </span>
            <span className="ot_status_shorter_status">
              {ticket.status && ticket?.status?.name}
            </span>
            <span>{justDate(ticket?.rma?.created_at)}</span>
            {showNotification(ticket)}
          </li>
        ))}
    </ul>
  );
};
