export const otStatusList = Object.freeze({
    general: ["En revision", "En Customer Service", "Presupuesto aceptado", "Reparacion gratuita", "Presupuesto rechazado"],
    toDeliver: ['Para entrega cliente en CS'],
    toConfirm: ['Presupuestado'],
    repaired: ['Cambio', 'Reparado', 'Presupuesto rechazado', 'Plan canje'],
    pendant: ["Evaluado", "Presupuesto rechazado"],
    inProduction: ["En tecnica", "Por revisar", "En revision", "Reparacion rechazada"],
    inTransit: ["En transito"],
    generalPDV:["En PDV"],
    toDeliverPDV: ['Para entrega cliente en PDV'],
});