import Axios from "axios";
import { TYPES } from "../../types/types";

export const otInfoSearch = (fd,setError) => {
  return async(dispatch) => {
        await Axios.post('api/rma/rma-info', fd, {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then(async(res) => {
            const { message, rma } = res.data
            if(rma.length > 0) {
              await dispatch(setClientRMASearchInfo(rma))
              return;
            }
            return setError({status:true,message})
        })
        .catch(err => {
          setError({status:true,message:err.response.data.message})
        })
  }
}

const setClientRMASearchInfo = rma => ({type: TYPES.setClientRMASearchInfo, rma})

export const otDeleteInfoSearch = () => ({type: TYPES.deleteClientRMASearchInfo})