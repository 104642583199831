import validator from './validator'
import formDataSetter from './formDataSetter'

const handleSubmit = ( otForm, setError ) => 
	new Promise((resolve, reject) => {
		const validated = validator(otForm, setError,reject)
		if( validated ) {

			setError({
				status:false,
				message:''
			})

			// resolve()
			// return formDataSetter(otForm)

			return resolve(formDataSetter(otForm))
		}
  })

export default handleSubmit
