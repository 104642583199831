import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import ReactToPdf from 'react-to-pdf'

const DownloadPDFModal = ({ 
	onHide, modalSize = 'lg', modalText, PDFinfo, PDFPreview, ...rest 
}) => {
  const ref = React.createRef();

	return (
    <Modal
      {...rest}
      size={modalSize}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{border:'none'}}>
        <Modal.Title id="contained-modal-title-vcenter">
          Vista previa del Voucher OT en PDF
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{margin:40, border:'1px solid grey', borderRadius:'0.3rem', overflow:'scroll'}}>
        <PDFPreview
          PDFinfo={PDFinfo}
          auxRef={ref}
        />
      </Modal.Body>
      <Modal.Footer style={{border:'none'}}>

        <Button onClick={onHide}>Close</Button>

        <ReactToPdf targetRef={ref} filename='voucher-ot-document.pdf'>  
          {({toPdf}) => (
            <Button variant="success" 
              onClick={auxComponent => toPdf(auxComponent)}
            >
              <i className="far fa-file-pdf"></i> Descargar PDF
            </Button>
          )}
        </ReactToPdf>
				{/* {
					typeof fnToInvoke === 'function'
					&& message.active
					&& <Button variant='success' onClick={onSuccess}>Enviar</Button>
				} */}
      </Modal.Footer>
    </Modal>
	)
}

export default DownloadPDFModal
