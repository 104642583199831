import Axios from "axios"
import { TYPES } from "../../types/types";
import { setHeader } from "../header";
import { isLoading, isNotLoading } from "../ui";

export const otGetVoucherInfo = () => {
  return async(dispatch) => {
        dispatch(isLoading());
        await Axios.get('api/voucher', {
            headers:{
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
        })
        .then(res => {
            dispatch( setHeader(res.data.title, '') );
            dispatch( uploadVouchers(res.data.vouchers) );
						return dispatch(isNotLoading());
        })
        .catch(err => {
          //console.log(err)
          dispatch(isNotLoading());
            //alert('Falló la búsqueda de información sobre OT, intente nuevamente: ', err);
        })
  }
}

export const uploadVouchers = (vouchers) => ({
    type: TYPES.setVoucherIndex,
    payload : vouchers
})
