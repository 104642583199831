import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";

import { Nav } from '../../components/NavBar/Nav';
import Routes from './routes';

const Dashboard = () => {  
	return (
		<div className='panel-general'>
			<Router>
				<Nav />
				<Routes />
			</Router>
		</div>
	)
}

export default Dashboard
