import makeRequest from "../../../../actions/makeRequest"
import { otGetVoucherInfo } from "../../../../actions/ot/ot-voucher"
import { postRequest } from "./requests"

export const submitFunction = ({voucher, dispatch, data}) => {
	const auxRequest = {...postRequest(voucher.voucher_id), data}
	return new Promise(async(resolve) => {
		const aux = await dispatch(makeRequest(auxRequest))
		dispatch(otGetVoucherInfo());
		resolve(aux)
	})
}


export const submitValidation = ({voucher,rmaCollection}) => {

  const {
    code,
    rma_id,
  } = voucher

  if( !code ) return {status:true, message:'Genere un código único de voucher'}
  if( !rma_id ) return {status:true, message:'Es necesario asignar un OT'}

  //const auxSlug = Number(rma_id.match(/\d+/g)[0])
  // const filteredSlug = rmaCollection.filter(rma => rma.slug === auxSlug)

  // if( auxSlug && filteredSlug.length !== 1 ) return {status:true, message:'Es necesario que la OT sea válida'}
  return false
}


export const editSubmitFunction = ({voucher, dispatch}) => {
	return (data) => {
		const auxRequest = {...postRequest(voucher.voucher_id), data}
		return new Promise(async(resolve) => {
			const aux = await dispatch(makeRequest(auxRequest))
			dispatch(otGetVoucherInfo());
			resolve(aux)
		})
	}
}
