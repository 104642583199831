/**
 * ### Tipos de charts / gráficos:
 *  - bar
 *  - line-bar-area
 *  - pie-with-padding
 *
 * Todos los charts tienen sus estilos y atributos base, pero, si es necesario,
 * se pueden agregar a este objeto como "attributes" y "style".
 * 
 * @example 
{
  id: "ot-por-local",
  label: "OT por local",
  to: "/panel/reportes/ot-por-local",
  type: "bar",
  showLegend: true,
  container:{
    style: {
      backgroundColor: 'red'
    },
  },
  svg:{
    attributes: {
      innerRadius:"50%",
      outerRadius:"70%",
      paddingAngle:15
    }
  }
},
 *
 * @TODO: Agregar más tipos al generador cuándo sea necesario
 * @RECORDAR: TODOS LOS ID TIENEN QUE SER ÚNICOS
 */
export default [
  {
    id: "ot-por-local",
    label: "OT por local",
    to: "/panel/reportes/ot-por-local",
    type: "bar",
  },
  {
    id: "ot-por-estado",
    label: "OT por estado",
    to: "/panel/reportes/ot-por-estado",
    type: "pie-with-padding",
  },
  {
    id: "ot-por-tecnico",
    label: "OT por técnico",
    to: "/panel/reportes/ot-por-tecnico",
    type: "bar",
  } /* ,
  {
    id: "ot-por-periodo-por-tecnico",
    label: "OT por período por técnico",
    to: "/panel/reportes/ot-por-periodo-por-tecnico",
    type: "pie-with-padding",
  }, */,
];
