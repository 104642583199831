import React from "react";
import { NavLink } from "vendor/react-router-dom";
import { links } from "./constants";

export const LayoutNavbar = () => {
  return (
    <div className="layout-navbar">
      <ul>
        {links.map(({ id, label, to, activeClassName }) => (
          <NavLink key={id} to={to} activeClassName={activeClassName}>
            {label}
          </NavLink>
        ))}
      </ul>
    </div>
  );
};
