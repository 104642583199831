import { deleteInfoHeader } from "../actions/header";
import { deleteContent, getTicketCount } from "../actions/tickets";
import { deleteDetail } from "../actions/ticket-detail";
import { otDeleteInfoSearch } from "../actions/ot/ot-search";
import { deleteClientInfo } from "../actions/ot/ot-create";
import { deleteCreateInfo } from "../actions/ticket-create";
import { deleteUserInfo } from "../actions/user/user-profile";

export const cleanTheStorage = (count = true) => {
    return (dispatch) => {
        dispatch(deleteUserInfo());
        dispatch(deleteInfoHeader());
        dispatch(deleteDetail());
        dispatch(otDeleteInfoSearch())
        dispatch(deleteClientInfo())
        dispatch(getTicketCount());
        dispatch(deleteContent());
        dispatch(deleteCreateInfo());
        if(count) {
            dispatch(getTicketCount());
        }
    }
}