import { apiInstance } from "vendor/axios";
import { setInStore } from "../store/actions";
import { TYPES } from "../store/types";

const storeType = TYPES.reportes;

const CONFIG = {
  url: "api/reportes",
  method: "GET",
};

const setConfig = (range = []) =>
  range?.length ? { ...CONFIG, params: { range } } : CONFIG;

export const useGetReportes = () => {
  const requestConfig = apiInstance();

  const getReportes = () => async (dispatch) => {
    const submitNow = JSON.parse(localStorage.getItem("reportes-submit"));
    const range = submitNow
      ? JSON.parse(localStorage.getItem("reportes-rango"))
      : [];
    return requestConfig
      .request(setConfig(range))
      .then(({ data: { data } }) => {
        dispatch(setInStore(storeType, data));
      })
      .catch(() => console.log("fail"));
  };

  return { getReportes };
};
