export const voucherState = {
	code:'',
	rma_id:'',
	date_from: new Date(),
	date_to: new Date(Date.now() + (1000 * 60 * 60 * 24 * 60)), // Dos meses
	description:'',
	client_id:'',
	active:false,
}

export const errorState = {
	status:false,
	message:''
}

export const modalState = {
  modalShow: false,
}
